import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink } from "react-router-dom"
import {getRouteBySlug} from '../../../constants/global'

class Menu extends Component {
	
	onMenuItemClicked = (id) => {
		const {onMenuItemClicked} = this.props
		onMenuItemClicked && onMenuItemClicked(id)
	}

	renderMenu = () => {
		const {homePage:{menu},
			currentPage,
			currentCategory,
			className,
			onMenuItemClicked,
			match
		} = this.props
		return (
			menu.map( (item) => {
				const {title, type, slug, active} = item.menuItem
				const linkPath = type === 'category'
					? `${getRouteBySlug('category').path}/${slug}`
					: `${getRouteBySlug(slug).path}`
				const location = {
					pathname: linkPath,
					state: { scrollPos: 333 }
				}
				if(!active) {
					return null
				}
				const currentClass = match && (location === match.url || match.url.includes(currentCategory))
					? ' --current'
					: ''
				return (
					<NavLink className={`${className}__item --${slug}${currentClass}`}
						    key={`menu-link-${slug}`}
						    to={location}
						    isActive={(match, location) => {
								const locationMatch = match && (location.pathname === match.url)
									|| slug === currentCategory
								return locationMatch;
							}}
						    onClick={ e => this.onMenuItemClicked(slug) }>
						<div className={`${className}__item__link`}>
							{title}
						</div>
					</NavLink>
				)
			})
		)
	}

	render() {
		const {
			className,
			currentCategory
		} = this.props
		return (
			<div className={className}>
				{this.renderMenu()}
				<NavLink className={`${className}__item`}
					    to={'/privacy'}
					    isActive={(match, location) => {
							const locationMatch = match && (location.pathname === match.url)
								|| currentCategory === 'privacy'
							return locationMatch;
						}}
					    onClick={ e => this.onMenuItemClicked('privacy') }>
					<div className={`${className}__item__link`}>Privacy Policy</div>
				</NavLink>
			</div>
		)
	}
}

Menu.propTypes = {
	onMenuItemClicked: PropTypes.func
}

Menu.defaultProps = {
	className: 'menu'
}

function mapStateToProps(state) {
  return {
  	homePage: state.pages.pages.find( page => page.slug === 'home'),
  	currentPage: state.ui.currentPage,
  	currentCategory: state.ui.currentCategory
  }
}

export default connect(mapStateToProps, {
	// performSetCurrentPage
})(Menu);
