import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ProjectsList from '../ProjectsList/ProjectsList'
import { bindActionCreators } from 'redux'
import {Redirect} from 'react-router-dom'
import { performSetCurrentPage } from '../../actions/action_ui'
import {
    getProjectCategory,
    getCollageOfTitleImages,
    getProjectsByCategory
} from '../../utils'
import {PageTitle} from '../CommonPageElement'
import Measure from 'react-measure'
import {ImageCollage, IMAGE_COLLAGE} from '../ImageCollage'
import {ROUTE_PATH} from '../../constants/global'

class GroupProject extends Component {
    constructor(props){
        super(props);
        this.state = {
            redirectPath: null,
            categoryId: null,
            groupProject: null,
            projects: null
        }
        const {
            match:{params:{categoryId}},
            currentCategory
        } = this.props
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {
            groupProjectsList,
            projects,
            match:{params:{groupSlug}},
            currentPage,
            performSetCurrentPage
        } = nextProps
        const groupProject = groupProjectsList.find( p => p.slug === groupSlug )
        const groupProjects = projects.filter( project => {
            const groupName = project.projectTaxonomy.projectGroup.projectGroupName
            return groupName && groupName.includes(groupProject.id)
        })
        const categoryId = groupProject.acf.groupTaxonomy.category.slug
        if(currentPage !== 'group') {
            const category = groupProject.acf.groupTaxonomy.category.slug
            performSetCurrentPage('group', category)
        }
        return {
            ...prevState,
            categoryId,
            groupProject,
            projects: groupProjects
        }
    }

    componentDidMount() {
        const $scrollArea = document.getElementsByClassName('scrollArea')
        if($scrollArea && $scrollArea.length) {
            $scrollArea[0].scrollTop = 0
        }
    }

    onCollageImageClicked = (e, obj) => {
        const {projectid} = obj
        const redirectPath = `${ROUTE_PATH.PROJECT.path}/${projectid}`
        this.setState({redirectPath})
    }

    renderCollage = () => {
        const { categoryId, projects } = this.state
        const collageImages = getCollageOfTitleImages(projects)
        return (
            <Fragment>
                <ImageCollage
                    images = {collageImages}
                    onClick={ this.onCollageImageClicked }
                    showTitles={true}
                    type={IMAGE_COLLAGE.TYPE.GROUP}
                />
            </Fragment>
        )
    }

    render() {
        const { redirectPath, groupProject } = this.state
        if(redirectPath) {
            return <Redirect push to={redirectPath} />
        }

        return (
            <Fragment>
                <div className="scrollArea">
                    <div className="content-wrapper">
                        <PageTitle className="category-page__title">
                            {groupProject.title.rendered}
                        </PageTitle>
                        {this.renderCollage()}
                    </div>
                </div>
            </Fragment>
        )
    }
}

GroupProject.propTypes = {}

function mapStateToProps(state) {
  return {
    currentPage: state.ui.currentPage,
    projects: state.projects.projects,
    categories: state.categories.categories,
    groupProjectsList: state.groupProjects.projects
  }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({
        performSetCurrentPage
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupProject);