import {
	FETCH_CATEGORIES
} from '../constants/actionTypes'

import {
	fetchCategories
} from '../services/wordpressAPI'

export function performFetchCategories() {
	const request = fetchCategories();
	return {
		type: FETCH_CATEGORIES,
		payload: request
	};
}