import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import MainMenu from '../MainMenu/MainMenu';
import { MenuToggleButton } from '../CommonPageElement/'
import SearchInput from '../SiteSearch/SearchInput'
import { performToggleMainMenu } from '../../actions/action_ui'
import { getCurrentPage } from '../../utils'
import {
    performToggleGalleryFormat,
    performUpdateSearchTerm,
    performClearSearchTerm
} from '../../actions/action_ui'
import './SiteHeader.css'

class SiteHeader extends Component {

    toggleMainMenu = (e, show) => {
        const {showMainMenu} = this.props
        if(!showMainMenu) {
            this.clearSearchTerm()
        }
        this.props.performToggleMainMenu(show)
    }

    handleSearch = (searchterm) => {
        const {showMainMenu} = this.props
        this.props.performUpdateSearchTerm(searchterm)
        if(searchterm.length && showMainMenu) {
            this.props.performToggleMainMenu(false)
        }
    }

    clearSearchTerm = (e) => {
        this.props.performClearSearchTerm()
    }

	render() {
        const {showMainMenu, currentPage, searchTerm, screenProps} = this.props
        const visibleClass = currentPage === 'home' ? ' --homepage' : ''
		return (
            <Fragment>
    			<header id="site-header" className={`site-header${visibleClass}`}>
                    <div className="content-wrapper --no-vertical-padding">
                        {screenProps.breakpoint.current === 'small' &&
                            <MenuToggleButton
                                className="site-header__menu"
                                active={showMainMenu}
                                onButtonClick={this.toggleMainMenu}
                            />
                        }
                        <Link className="site-header__brand" to={`/`}>HEYWOOD<span>&amp;</span>CONDIE</Link>
                        <div className="site-header__search">
                            <SearchInput
                                onChange={this.handleSearch}
                                onCancel={this.clearSearchTerm}
                                searchTerm={searchTerm}
                            />
                        </div>
                    </div>
                </header>
                { showMainMenu &&
                    <MainMenu
                        location={this.props.location}
                        onMenuItemClicked={(e) => this.toggleMainMenu(e, false) }
                    />
                }
            </Fragment>
		)
	}
}

function mapStateToProps(state) {
  return {
    searchTerm: state.ui.searchTerm,
    screenProps: state.ui.screenProps,
    showMainMenu: state.ui.showMainMenu,
    currentPage: state.pages.currentPage,
    galleryFormat: state.ui.galleryFormat,
    showToggleButton: state.pages.currentPage === 'category'
  }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({
        performToggleMainMenu,
        performToggleGalleryFormat,
        performUpdateSearchTerm,
        performClearSearchTerm
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteHeader);