
// This file will have all the logic for getting the data from the WordPress REST API endpoints.
// For talking to the API we’ll use axios.

import axios from 'axios';
import {WP_URL} from '../constants/stageVariables'
// import alt   from '../alt/alt.js';

// const WP_URL = 'http://approval.heywoodandcondie.com/wp'
const perPageQuery = '?per_page=100' //?filter[posts_per_page]=100'

const wpEndpoint = {
	pages: `${WP_URL}/wp-json/wp/v2/pages`,
	posts: `${WP_URL}/wp-json/wp/v2/posts`,
	categories: `${WP_URL}/wp-json/wp/v2/categories`,
  group: `${WP_URL}/wp-json/wp/v2/group`,
  project: `${WP_URL}/wp-json/wp/v2/project`,
  groupProjects: `${WP_URL}/wp-json/wp/v2/groupprojects`,
  comboProjects: `${WP_URL}/wp-json/wp/v2/comboprojects`,
  // project: `${WP_URL}/wp-json/acf/v3/project`,
  media: `${WP_URL}/wp-json/wp/v2/media`,
  venue: `${WP_URL}/wp-json/wp/v2/venue`,
  news: `${WP_URL}/wp-json/wp/v2/news`
}

// const getVersion = () => {
//   axios.get('https://platform.immense.ai/api/v2/version',{
//     headers: {'Access-Control-Allow-Origin': '*'},
//     responseType: 'json'
//   })
//     .then( response => {
//       console.log(response)
//     })
//     .catch( error => {
//       console.error(error)
//     })
// }

/**
 * Get all projects
 * @returns {axiosPromise} Promise to return all projects
 */
export function fetchProjects() {
  // getVersion()
  return axios.get(`${wpEndpoint.project}${perPageQuery}`);
}

export function fetchGroupProjects() {
  return axios.get(`${wpEndpoint.groupProjects}${perPageQuery}`);
}

export function fetchComboProjects() {
  return axios.get(`${wpEndpoint.comboProjects}${perPageQuery}`);
}

/**
 * Get a specific project
 * @param {String} id - Project ID
 * @returns {axiosPromise} Promise to return project
 */
export function fetchProjectById(id) {
  return axios.get(`${wpEndpoint.project}/${id}`);
}

/**
 * Get all venues
 * @returns {axiosPromise} Promise to return all venues
 */
export function fetchVenues() {
  return axios.get(`${wpEndpoint.venue}${perPageQuery}`);
}

/**
 * Get a specific venue
 * @param {String} id - Venue ID
 * @returns {axiosPromise} Promise to return venue
 */
export function fetchVenueById(id) {
  return axios.get(`${wpEndpoint.venue}/${id}`);
}

/**
 * Get all pages
 * @returns {axiosPromise} Promise to return all pages
 */
export function fetchPages() {
  return axios.get(`${wpEndpoint.pages}`);
}

/**
 * Get a specific page
 * @param {String} id - Page ID
 * @returns {axiosPromise} Promise to return page
 */
export function fetchPageById(id) {
  return axios.get(`${wpEndpoint.pages}/${id}`);
}

export function fetchCategories() {
  return axios.get(`${wpEndpoint.categories}`);
}

export function fetchGroups() {
  return axios.get(`${wpEndpoint.group}`);
}

/**
 * Get all media
 * @returns {axiosPromise} Promise to return all media
 */
export function fetchMedia() {
  return axios.get(`${wpEndpoint.media}${perPageQuery}`);
}

/**
 * Get all news items
 */
export function fetchNews() {
  return axios.get(`${wpEndpoint.news}${perPageQuery}`);
}