import React, { Component } from 'react'
import CookieConsent from "react-cookie-consent";
import { NavLink } from "react-router-dom"

class ConsentBar extends Component {
    
     render() {
          return (
               <CookieConsent
                    location="bottom"
                    buttonText="GOT IT"
                    cookieName="consentToCookies"
                    style={{ background: "#2B373B" }}
                    buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                    expires={150}
               >
                    We use cookies to ensure that we give you the best experience on our website.{" "}
                    <NavLink className="privacy-policy-link" to={'/privacy'}>Click here for more information.</NavLink>
               </CookieConsent>
          )
     }
}

ConsentBar.defaultProps = {}

ConsentBar.propTypes = {}

export default ConsentBar