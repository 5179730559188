import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'

class SearchInput extends Component {
    constructor(props){
        super(props)
    }

    handleOnChange = (e) => {
        this.props.onChange(e.target.value)
    }

    onCancelClicked = (e) => {
        e.preventDefault()
        const active = this.props.searchTerm.length
        if(active) {
            this.props.onCancel()
        }
    }
    

	render() {
        const {searchTerm, className} = this.props
        const active = searchTerm.length
        const iconClass = active ? "--icon-cross" : "--icon-search"
        const inputClass = active ? "--active" : "--inactive"
		return (
            <Fragment>
                <input
                    className={`site-search-input ${inputClass}`}
                    type="text"
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => this.handleOnChange(e)}
                />
                <button className={`site-search-icon icon ${iconClass}`}
                        onClick={(e) => this.onCancelClicked(e)}
                ></button>
            </Fragment>
		)
	}
}

SearchInput.propTypes = {
    onChange: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    searchTerm: PropTypes.string.isRequired
}
export default SearchInput;
