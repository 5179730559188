import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link } from "react-router-dom"
import { throttle, debounce } from 'lodash'
import { PageTitle, Menu } from '../CommonPageElement'
import BackgroundImage from './Widget/BackgroundImage'
import { performSetCurrentPage } from '../../actions/action_ui'
import HomePageMenu from './Widget/HomePageMenu'
import { getRouteBySlug } from '../../constants/global'
import './Home.css'
import { NavLink } from "react-router-dom"
import {ImageCollage, IMAGE_COLLAGE} from '../ImageCollage'

class Home extends Component {
	constructor(props){
		super(props)
	}

     onMenuItemClicked = (id) => {
     }

    renderCollage = (images) => {
        if(!images.length){
            return null
        }
        return (
            <ImageCollage
                images={images}
                onClick={ this.onCollageImageClicked }
                showGroups={false}
                type={IMAGE_COLLAGE.TYPE.PROJECT}
            />
        )
    }

     __renderMenu = () => {
          const {
               homePage:{menu},
               currentCategory
          } = this.props
          return (
               <div className="home-page__menu">
               {
                    menu.map( item => {
                         const {
                              title,
                              slug,
                              type,
                              backgroundImage
                         } = item.menuItem
                         const style = {
                              backgroundImage: `url(${backgroundImage})`
                         }
                         const linkPath = type === 'category'
                              ? `${getRouteBySlug('category').path}/${slug}`
                              : `${getRouteBySlug(slug).path}`
                         const activeClass = slug === currentCategory
                         const location = {
                              pathname: linkPath,
                              state: { scrollPos: 333 }
                         }
                         return (
                              <NavLink className={`home-page__menu__item --${slug}`}
                                       key={`menu-link-${slug}`}
                                       to={location}
                                       onClick={ e => this.onMenuItemClicked(slug) }>
                                   <img src={backgroundImage} alt="background-image"
                                        className="home-page__menu__item__background" />
                                   <div className="home-page__menu__item__title">
                                        <span>{title}</span>
                                   </div>
                              </NavLink>
                         )
                    })
               }
               </div>
          )
     }

     getLatestNewsImage = (defaultImage) => {
      const {newsPage:{articles}} = this.props
      if(!articles || !Array.isArray(articles) || !articles.length) {
        return defaultImage
      }
      return articles[0].articleDetails.image
     }

     renderMenu = () => {
          const {
               homePage:{menu},
               currentCategory
          } = this.props
          return (
               <div className="home-page__menu">
               {
                    menu.map( item => {
                         const {
                              title,
                              slug,
                              type,
                              backgroundImage,
                              active
                         } = item.menuItem
                         if(!active) {
                          return null
                         }
                         let bgImage = backgroundImage
                         if(slug === 'news') {
                          bgImage = this.getLatestNewsImage(backgroundImage)
                         }
                         const style = {
                              backgroundImage: `url(${bgImage})`
                         }
                         const linkPath = type === 'category'
                              ? `${getRouteBySlug('category').path}/${slug}`
                              : `${getRouteBySlug(slug).path}`
                         const activeClass = slug === currentCategory
                         const location = {
                              pathname: linkPath,
                              state: { scrollPos: 333 }
                         }
                         return (
                              <NavLink className={`home-page__menu__item --${slug}`}
                                       key={`menu-link-${slug}`}
                                       to={location}
                                       onClick={ e => this.onMenuItemClicked(slug) }>
                                   <img src={bgImage} alt="background-image"
                                        className="home-page__menu__item__background" />
                                   <div className="home-page__menu__item__title">
                                        <span>{title}</span>
                                   </div>
                              </NavLink>
                         )
                    })
               }
               </div>
          )
     }

	render() {
		return (
			<div className="home-page">
				{/*<Menu className="home-page__menu"/>*/}
                    <div className="content-wrapper">
                         { this.renderMenu() }
                    </div>
			</div>
		)
	}
}

Home.propTypes = {

}

Home.defaultProps = {

}

function mapStateToProps(state) {
  return {
  	homePage: state.pages.pages.find( page => page.slug === 'home'),
    newsPage: state.pages.pages.find( page => page.slug === 'news'),
  	categories: state.categories.categories,
  	currentCategory: state.categories.currentCategory,
  	currentPage: state.pages.currentPage,
  	breakpoint: state.ui.screenProps.breakpoint.current
  }
}

export default connect(mapStateToProps, {
	performSetCurrentPage
})(Home);
