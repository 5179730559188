
import React, {Component} from "react"
import PropTypes from 'prop-types'
import {ICONS} from '../../constants/global'

class SVG extends Component {

    getIconClassFromList = () => {
      const {icon} = this.props
      if(icon) {
        // This will eventually return a UI Icon from constants.js
        // return ICONS.get(iconId).classname
        return ICONS.get(icon).classname
      }
      return ''
    }

    renderUseString = (classname) => {
      // return `<use xlink:href="#${classname}"></use>`
      return `<use xlink:href="assets/icons/symbol-defs.svg#${classname}"></use>`
    }

    render() {
      const {selectorClass, iconClass} = this.props
      const classname = iconClass || this.getIconClassFromList()
      return (
        <svg
          className={`${selectorClass} ${classname}`}
          dangerouslySetInnerHTML={{__html: this.renderUseString(classname) }}
        />
      )
    }
}

SVG.propTypes = {
  iconClass: PropTypes.string,
  selectorClass: PropTypes.string,
  icon: PropTypes.string
}

SVG.defaultProps = {
  selectorClass: 'icon'
}

export default SVG
