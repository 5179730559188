import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'

class BackgroundImage extends Component {
	constructor(props){
		super(props)
	}

	render() {
		const {imageURL, imageId} = this.props
		const style = {
			backgroundImage: `url(${imageURL})`
		}
		return (
			<div className="home-page__background-image"
				 id={imageId}
				 style={style}>
			</div>
		)
	}
}

BackgroundImage.propTypes = {
	imageURL: PropTypes.string.isRequired,
	imageId: PropTypes.string.isRequired
}

BackgroundImage.defaultProps = {
}

export default BackgroundImage
