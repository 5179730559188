import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ProjectsList from '../ProjectsList/ProjectsList'
import { bindActionCreators } from 'redux'
import {Redirect} from 'react-router-dom'
import { performSetCurrentPage } from '../../actions/action_ui'
import {
	getProjectCategory,
	getCollageOfTitleImages,
	getProjectsByCategory
} from '../../utils'
import {
	PageTitle,
	ScrollArea
} from '../CommonPageElement'
import Measure from 'react-measure'
import {ImageCollage} from '../ImageCollage'
import './Category.css'
import {ROUTE_PATH} from '../../constants/global'

class Category extends Component {
	constructor(props){
		super(props);
		this.state = {
			redirectPath: null,
			category: null,
			categoryProjects: null
		}
		const {
			match:{params:{categoryId}},
			currentCategory
		} = this.props

		if(currentCategory !== categoryId) {
			props.performSetCurrentPage('category', categoryId)
		}
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const {projects, match:{params:{categoryId}}} = nextProps
		return {
			...prevState,
			category: categoryId,
			categoryProjects: getProjectsByCategory(projects, categoryId)
		}
	}

	componentDidMount() {
		const $scrollArea = document.getElementsByClassName('scrollArea')
		if($scrollArea && $scrollArea.length) {
			$scrollArea[0].scrollTop = 0
		}
	}

	shouldComponentUpdate(nextProps) {
		const {
			match:{params:{categoryId}},
			currentCategory
		} = nextProps
		const { category } = this.state

		if(currentCategory !== categoryId) {
			this.props.performSetCurrentPage('category', categoryId)
			return false
		}
		return true
	}

	onCollageImageClicked = (e, obj) => {
		const {projectid, comboid, groupid} = obj
		let redirectPath
		if(groupid) {
			redirectPath = `${ROUTE_PATH.GROUP.path}/${groupid}`
		}
		else if(comboid) {
			redirectPath = `${ROUTE_PATH.COMBO.path}/${comboid}`
		}
		else {
			redirectPath = `${ROUTE_PATH.PROJECT.path}/${projectid}`
		}
		this.setState({redirectPath})
	}

	renderCollage = () => {
		const { categories, media } = this.props
		const { category, categoryProjects } = this.state
		const categoryName = categories.find( c => c.slug === category).name
		const collageImages = getCollageOfTitleImages(categoryProjects)
		return (
			<Fragment>
				<ImageCollage
					images = {collageImages}
					onClick={ this.onCollageImageClicked }
					showTitles={true}
				/>
			</Fragment>
		)
	}

	render() {
		const { redirectPath, category } = this.state
		if(redirectPath) {
			return <Redirect push to={redirectPath} />
		}
		const {categories} = this.props
		const categoryName = categories.find( c => c.slug === category).name

		return (
			<Fragment>
				<ScrollArea className="scrollArea"
						  scrollKey={category}>
					<div className="content-wrapper">
						<PageTitle className="category-page__title">
		                        {categoryName}
		                    </PageTitle>
						{this.renderCollage()}
					</div>
				</ScrollArea>
			</Fragment>
		)
	}
}

Category.propTypes = {}

function mapStateToProps(state) {
  return {
  	projects: state.projects.projects,
  	categories: state.categories.categories,
  	media: state.projects.media,
  	currentCategory: state.ui.currentCategory
  }
}

function mapDispatchToProps(dispatch){
	return bindActionCreators({
		performSetCurrentPage
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Category);