import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import './About.css'
import { performSetCurrentPage } from '../../actions/action_ui'
import {
	PageTitle,
	ScrollArea
} from '../CommonPageElement'
import { ROUTE_PATH } from '../../constants/global'

class About extends Component {
	constructor(props){
		super(props)
		props.performSetCurrentPage('about')
	}

	renderContent = () => {
		const {about} = this.props
		const pageInfo = about.content.about_page_info
		if(!pageInfo && pageInfo.length) {return null}
		return (
			pageInfo.map( (item, index) => {
				const section = item.info_section
				const {horizontalAlignment, backgroundColour} = section.imageOptions
				const hasText = section.section_text && section.section_text.length
				const hasImage = section.section_image && section.section_image.length
				const alignment = hasText && hasImage ? horizontalAlignment : 'center'
				if(!hasText && !hasImage) {
					return null
				}
				return (
					<section key={`about-page-section-${index}`}
						    className={`about-page__content__section --${alignment}`}
					>
						{ hasText &&
	                    		<div className="about-page__content__section__text"
								dangerouslySetInnerHTML={{__html: section.section_text}}>
							</div>
						}
						{ hasImage &&
							<div className="about-page__content__section__image">
								<img src={section.section_image} alt="about page layout image" />
							</div>
						}
					</section>
				)
			})
		)
	}

	render() {
		return (
			<ScrollArea className="about-page"
					  scrollKey={ROUTE_PATH.ABOUT.slug}>
				<div className="content-wrapper">
					<PageTitle className="about-page__title">
	                        About
	                    </PageTitle>
					<div className="about-page__content">
				        {this.renderContent()}
					</div>
				</div>
			</ScrollArea>
		)
	}
}

About.propTypes = {

}

function mapStateToProps(state) {
  return {
  	about: state.pages.pages.find( page => {
  		return page.slug === 'about'
  	})
  }
}

export default connect(mapStateToProps, {
	performSetCurrentPage
})(About);