import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'
import {performToggleMainMenu} from '../../../actions/action_ui'

class MenuToggleButton extends Component {

    render() {
        const {onButtonClick, showMainMenu} = this.props
        const activeClass = showMainMenu ? ' --active' : ''
        
        return (
            <div
                className={`menu-toggle-button${activeClass}`}
                onClick={(e) => onButtonClick(e)}
            >
                <div className="menu-toggle-button__bar1"></div>
                <div className="menu-toggle-button__bar2"></div>
                <div className="menu-toggle-button__bar3"></div>
            </div>
        )
    }
}

MenuToggleButton.defaultProps = {
    showMainMenu: false
}

MenuToggleButton.propTypes = {
    onButtonClick: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  return {
    showMainMenu: state.ui.showMainMenu
  }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({ performToggleMainMenu }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuToggleButton);
