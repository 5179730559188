import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {scrollPos} from '../../../utils'
import ScrollManager from '../../ScrollManager/ScrollManager'

class ScrollArea extends Component {

    componentDidMount() {
        const {defaultClassName} = this.props
        const $scrollArea = document.getElementsByClassName(defaultClassName)
        if($scrollArea && $scrollArea.length) {
            $scrollArea[0].scrollTop = 0
        }
    }

    onScroll = () => {

    }

    render() {
        const {
            children,
            defaultClassName,
            className,
            scrollKey,
            currentPage,
            currentCategory
        } = this.props
        const parsedClass = className ? ` ${className}` : ''
        const classes = `${defaultClassName}${parsedClass}`
        const sKey = scrollKey ? scrollKey : `random-scrollkey-${Math.round(Math.random()*9999)}`
        return (
            <ScrollManager scrollKey={sKey}>
                {({ connectScrollTarget, ...props }) => 
                <div ref={connectScrollTarget} className={classes}>
                    {children}
                </div>
                }
            </ScrollManager>
        )
    }
}

ScrollArea.defaultProps = {
    defaultClassName: "scrollArea"
}

ScrollArea.propTypes = {
    scrollKey: PropTypes.string
}

function mapStateToProps(state) {
  return {
    currentPage: state.ui.currentPage,
    currentCategory: state.ui.currentCategory
  }
}

export default connect(mapStateToProps, null)(ScrollArea);