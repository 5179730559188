import {
	FETCH_PROJECTS_STARTED,
	FETCH_PROJECTS_COMPLETED,
	GET_PROJECTS_BY_CATEGORY,
	FETCH_PROJECT_BY_ID,
	SET_CURRENT_PROJECT,
	FETCH_VENUES,
	GET_VENUES,
	SEARCH_PROJECTS_BY_TITLE,
	FETCH_MEDIA,
	FETCH_GROUPS
} from '../constants/actionTypes'
import {
	shapeWordpressProject,
	shapeWordpressVenue,
	shapeWordpressMedia,
	shapeWordpressGroup
} from '../utils'
import { LOADING_STATE } from '../constants/global'
import moment from 'moment' 

const initialState = {
	projects: [],
	allProjects: [],
	venues: [],
	media: [],
	currentProject: null,
	currentMedia: null,
	currentSearchProjects: [],
	projectsLoading: LOADING_STATE.IDLE,
	venuesLoading: LOADING_STATE.IDLE,
	mediaLoading: LOADING_STATE.IDLE
}

function fetchProjectsStarted(state, action){
	return {
		...state,
		projectsLoading: LOADING_STATE.STARTED
	}
}

function fetchProjectsCompleted(state, action){
	const {data} = action.payload
	function getMostRecentDate(sDate, eDate) {
	    const startdate = sDate && sDate !== ''
	        ? moment(sDate)
	        : null
	    const enddate = eDate && eDate !== ''
	        ? moment(eDate)
	        : null
		return enddate
			? enddate
			: startdate
	}
	data.sort( (project1, project2) => {
		const startdate1 = project1.acf.projectDates.projectStartDate
		const enddate1 = project1.acf.projectDates.projectEndDate
		const startdate2 = project2.acf.projectDates.projectStartDate
		const enddate2 = project2.acf.projectDates.projectEndDate
		const date1 = getMostRecentDate(startdate1, enddate1)
		const date2 = getMostRecentDate(startdate2, enddate2)
		if(date1 && date2) {
			return date1.diff(date2) * -1
		}
		if(date1) {
			return -1
		}
		return 1
	})
	return {
		...state,
		projectsLoading: LOADING_STATE.COMPLETED,
		projects: data.filter( project => !project.acf.projectArchived )
			.map(project => {
				return shapeWordpressProject(project, state.venues)
			}),
		allProjects: data.map(project => {
				return shapeWordpressProject(project, state.venues)
			})
	}
}

function fetchProjectsById(state, action){
	return {
		...state,
		projects: action.payload.data
	}
}

function fetchVenues(state, action){
	const {data} = action.payload
	return {
		...state,
		venuesLoading: LOADING_STATE.COMPLETED,
		venues: data && data.map(venue => {
			return shapeWordpressVenue(venue)
		}) || []
	}
}

function fetchMedia(state, action){
	const {data} = action.payload
	return {
		...state,
		mediaLoading: LOADING_STATE.COMPLETED,
		media: data.map(media => {
			return shapeWordpressMedia(media)
		})
	}
}

function setCurrentProject(state, action){
	const { id } = action
	const { projects } = state
	return {
		...state,
		currentProject: projects.find( (project) => {
			return project.id == id
		}) || null
	}
}

function getProjectsByCategory(state, action){
	const {category} = action
	const { projects } = state
	return {
		...state,
		currentProject: null,
		currentMedia: null,
		currentCategoryProjects: projects.filter( (project) => {
			return project.projectTaxonomy.projectCategory.slug == category
		})
	}
}

function searchProjectsByTitle(state, action){
	const { searchterm } = action
	const { projects } = state
	return {
		...state,
		currentSearchProjects: projects.filter( (project) => {
			return project.projectTitle.search(`/${searchterm}/i`)
		})
	}
}

export default function ProjectsReducer(state = initialState, action) {
	switch (action.type) {

		case FETCH_PROJECTS_STARTED:
			return fetchProjectsStarted(state, action)

		case FETCH_PROJECTS_COMPLETED:
			return fetchProjectsCompleted(state, action)
			
// ---------------------------------------------------------

		case SET_CURRENT_PROJECT:
			return setCurrentProject(state, action)

		case GET_PROJECTS_BY_CATEGORY:
			return getProjectsByCategory(state, action)

		case FETCH_VENUES:
			return fetchVenues(state, action)

		case FETCH_MEDIA:
			return fetchMedia(state, action)
			
		default:
			return state
	}
}
