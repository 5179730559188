import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {
	PageTitle,
	ScrollArea
} from '../CommonPageElement'
import {
    getVenueAddressString,
    getVenueAddress
} from '../../utils'
import {ROUTE_PATH} from '../../constants/global'
import './Contact.css'
import { performSetCurrentPage } from '../../actions/action_ui'

class Contact extends Component {
	constructor(props){
		super(props)
		props.performSetCurrentPage('contact')
	}

	renderContactDetails = () => {
		const {
			contactPage,
			contactPage:{mainAddress},
			contactPage:{mainAddress:{telephone}},
			contactPage:{mainAddress:{mobile}},
			contactPage:{mainAddress:{email}},
			contactPage:{galleryAddress}
		} = this.props
		const galleryTel = galleryAddress.telephone
		const galleryMobile = galleryAddress.mobile
		const galleryEmail = galleryAddress.email
		const galleryWebsite = galleryAddress.websiteURL.split('//')[1]
        const mainAddressFields = getVenueAddress(mainAddress.address)
        const galleryAddressFields = getVenueAddress(galleryAddress.address)
        const hasMainAddress = mainAddressFields.join('').length
        const hasGalleryAddress = galleryAddressFields.join('').length
        return (
        	<Fragment>
	        	{hasMainAddress &&
	    			<div className="contact-page__main-address">
		        		{mainAddressFields.map( (field, index) => {
		        			return index === 0
		        				? <div key={`main-address-field-${index}`}><strong>{field}</strong></div>
		        				: <div key={`main-address-field-${index}`}>{field}</div>
		        		})}
		        		{ telephone && <div>{telephone}</div> }
		        		{ mobile && <div>{mobile}</div> }
		        		{ email && <a href={`mailto:${email}`}>{email}</a> }
						{this.renderSocialLinks()}
	        		</div>
	        	}
	        	{hasGalleryAddress &&
	    			<div className="contact-page__gallery-address">
		        		{galleryAddressFields.map( (field, index) => {
		        			return index === 0
		        				? <div key={`gallery-address-field-${index}`}><strong>{field}</strong></div>
		        				: <div key={`gallery-address-field-${index}`}>{field}</div>
		        		})}
		        		{ galleryEmail && <a href={`mailto:${galleryEmail}`}>{galleryEmail}</a> }
		        		{ galleryWebsite && <a href={`http://${galleryWebsite}`} target="_Blank">{galleryWebsite}</a> }
	        		</div>
	        	}
        	</Fragment>
        )
	}

	renderSocialLinks = () => {
		const { contactPage:{socialMediaLinks} } = this.props
		return (
			<nav className="contact-page__social">
				{/*<a className="contact-page__social__link --facebook"
				   target="_blank"
				   href={socialMediaLinks.facebook} />*/}
				<a className="contact-page__social__link --twitter"
				   target="_blank"
				   href={socialMediaLinks.twitter} />
				<a className="contact-page__social__link --instagram"
				   target="_blank"
				   href={socialMediaLinks.instagram} />
				<a className="contact-page__social__link --youtube"
				   target="_blank"
				   href={socialMediaLinks.youtube} />
			</nav>
		)
	}

	render() {
		return (
			<ScrollArea scrollKey={ROUTE_PATH.CONTACT.slug}>
				<div className="content-wrapper">
					<PageTitle className="combo-project-page__info__title">
	                        Get in touch
	                    </PageTitle>
					<div className="contact-page">
				        {this.renderContactDetails()}
					</div>
				</div>
			</ScrollArea>
		)
	}
}

Contact.propTypes = {

}

function mapStateToProps(state) {
  return {
  	contactPage: state.pages.pages.find( page => page.slug === 'contact')
  }
}

export default connect(mapStateToProps, {
	performSetCurrentPage
})(Contact);