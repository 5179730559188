import {
	FETCH_PAGES_STARTED,
	FETCH_PAGES_COMPLETED,
	FETCH_PAGES_ERROR,
	FETCH_PAGE_BY_ID,
	GET_PAGES
} from '../constants/actionTypes'

import {
	shapeWordpressPage
} from '../utils'
import { LOADING_STATE } from '../constants/global'

const initialState = {
	pages: [],
	errorLoadingPages: {},
	pagesLoading: LOADING_STATE.IDLE
}

export default function PagesReducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_PAGES_STARTED:
			return {
				...state,
				pagesLoading: LOADING_STATE.STARTED
			}

		case FETCH_PAGES_COMPLETED:
			return {
				...state,
				pagesLoading: LOADING_STATE.COMPLETED,
				pages: action.payload.data && action.payload.data.map(page => {
					return shapeWordpressPage(page) || {}
				}) || state.pages
			}

		case FETCH_PAGES_ERROR:
		console.error(action.payload)
			return {
				...state,
				pagesLoading: LOADING_STATE.ERROR,
				errorLoadingPages: action.payload
			}
			
		default:
			return state
	}
}
