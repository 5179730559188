import { combineReducers } from 'redux';
import ProjectsReducer from './reducer_projects';
import ComboProjectsReducer from './reducer_comboProjects';
import GroupProjectsReducer from './reducer_groupProjects';
import PagesReducer from './reducer_pages';
import CategoriesReducer from './reducer_categories';
import UIReducer from './reducer_ui';

const rootReducer = combineReducers({
  // state: (state = {}) => state
  projects: ProjectsReducer,
  comboProjects: ComboProjectsReducer,
  groupProjects: GroupProjectsReducer,
  pages: PagesReducer,
  categories: CategoriesReducer,
  ui: UIReducer
});

export default rootReducer;
