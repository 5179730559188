import React, {Component} from 'react'
import PropTypes from 'prop-types'
import './FlipPanel.css'

class FlipPanel extends Component {
    render() {
        const {children, currentSide} = this.props
        return (
            <div className={`flip-container --show-${currentSide}`}>
                <div className="flip-content">
                    <div className="flip-content__front">
                        {children[0]}
                    </div>
                    <div className="flip-content__back">
                        {children[1]}
                    </div>
                </div>
            </div>
        )
    }
}

FlipPanel.defaultProps = {
    currentSide: 'front'
}

FlipPanel.propTypes = {
    children: PropTypes.arrayOf(PropTypes.element.isRequired),
    currentSide: PropTypes.string
}

export default FlipPanel