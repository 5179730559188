import React from 'react'
import PropTypes from 'prop-types'
import { getProjectCategory } from '../../utils'
import ProjectsListItem from '../ProjectsListItem/ProjectsListItem'
import './ProjectsList.css'

class ProjectsList extends React.Component {

  renderProjectList = () => {
    const { projects, media } = this.props

    return projects.map( project => {
      return (
        <ProjectsListItem
          key={`project-list-item-${project.id}`}
          project={project}
          media={media}
        />
      )
    })
    
  }

	render() {
		return (
      <div className="grid-container fluid projects-list">
        <div className="grid-x">
          {this.renderProjectList()}
        </div>
      </div>
		)
	}
}

ProjectsList.propTypes = {
  projects: PropTypes.array.isRequired,
  media: PropTypes.array.isRequired
}

export default ProjectsList