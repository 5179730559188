import React, {Component} from 'react'
import PropTypes from 'prop-types'

class PageTitle extends Component {
     render() {
          const {children, className} = this.props
          const parsedClass = className && className.length ? ` ${className}` : ''
          const classes = `page-title${parsedClass}`
          return (
               <div className={classes}>
                    <h1>{children}</h1>
               </div>
          )
     }
}

PageTitle.propTypes = {
    subTitle: PropTypes.string
}

export default PageTitle;