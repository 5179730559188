export function asyncFetchDataStarted(type, id = "") {
    return function (dispatch) {
        dispatch({
            type: type,
            id: id
        })
    }
}

export function asyncFetchDataCompleted(type, data) {
    return function (dispatch) {
        dispatch({
            type: type,
            payload: data
        })
    }
}

export function asyncFetchDataError(type, error, id = "") {
    return function (dispatch) {
        dispatch({
            type: type,
            payload: error,
            id: id
        })
    }
}