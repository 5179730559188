import React, {Component} from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getVenueAddress } from '../../../utils'

class ProjectLocation extends Component {
	render() {
		const {
			className,
			defaultClassName,
			venues,
			project,
			project:{projectAddress},
			project:{projectAddress:{addressString}},
			project:{projectLocation:{projectEventTitle}}
		} = this.props
		const parsedClass = className && className.length ? ` ${className}` : ''
		const addressFields = getVenueAddress(projectAddress)

		if(!addressFields.join('').length) {
			return null
		}
		return (
			<div className={`${defaultClassName}${parsedClass}`}>
				<div className={`${defaultClassName}__detail`}>
					{ projectEventTitle !== "" && <h3>{projectEventTitle}</h3> }
					{ projectAddress.title !== "" && <h4>{projectAddress.title}</h4> }
					<div className={`${defaultClassName}__address`}>
						{addressFields.map( (field, index) => {
							return <span key={`address-field-${index}-${project.id}`}>{`${field}${index<addressFields.length-1 ? ', ' : ''}`}</span>
						})}
					</div>
				</div>
			</div>
		)
	}
}
	
ProjectLocation.defaultProps = {
	defaultClassName: 'common-project-location'
}

ProjectLocation.propTypes = {
	project: PropTypes.object.isRequired
}

function mapStateToProps(state) {
	return {
		venues: state.projects.venues
	}
}

export default connect(mapStateToProps, null)(ProjectLocation);
