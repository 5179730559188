import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import {Redirect, Link} from 'react-router-dom'
import './SiteSearch.css'
import {ImageCollage} from '../ImageCollage'
import {
    getCollageOfTitleImages,
    getProjectById
} from '../../utils'
import {
    performUpdateSearchTerm,
    performSetCurrentPage,
    performClearSearchTerm
} from '../../actions/action_ui'
import {
    ROUTE_PATH,
    POST_TYPE
} from '../../constants/global'

class SearchResults extends Component {
    constructor(props){
        super(props)
        this.state = {
            selectedProjectId: null,
            selectedProjectType: null
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        const {searchTerm} = nextProps
        const {selectedProjectId} = nextState

        // If a project is selected then clear the searchTerm
        // and update the prevSearchTerm
        if(selectedProjectId && searchTerm.length) {
            this.props.performClearSearchTerm(true)
            // return false
        }
        return true
    }

    setProjectId = (projectId, projectType = POST_TYPE.PROJECT) => {
        if(projectId !== this.state.selectedProjectId) {
            this.setState({
                selectedProjectId: projectId,
                selectedProjectType: projectType
            })
        }
    }

    getProjectNames = (projects, projectIds) => {
        if(!(projectIds && projectIds.length)) {
            return null
        }
        
        const filteredProjects = projects.filter( project => {
            return projectIds.includes(project.id)
        })

        return filteredProjects && filteredProjects.map( p => p.projectTitle)
    }

    searchProjects = () => {
        const {
            projects,
            searchTerm,
            groupProjects,
            comboProjects
        } = this.props
        const filteredProjects = projects.filter( (project) => {
            const projectTags = project.projectTaxonomy.projectTags
            const projectGroup = project.projectTaxonomy.projectGroup.projectGroupName
            const groupId = projectGroup && projectGroup[0]
            const groupTitle = groupId && groupProjects.find( group => group.id === groupId).title.rendered
            const projectCombo = project.projectTaxonomy.projectCombo.projectComboName
            const comboId = projectCombo && projectCombo[0]
            const comboTitle = comboId && comboProjects.find( combo => combo.id === comboId).title.rendered
            return project.projectTitle.toLowerCase().includes(searchTerm.toLowerCase())
                || project.projectAddress.title.toLowerCase().includes(searchTerm.toLowerCase())
                || project.projectAddress.addressString.toLowerCase().includes(searchTerm.toLowerCase())
                || projectTags && projectTags.map( tag => tag.name.toLowerCase()).includes(searchTerm.toLowerCase())
                || groupTitle && groupTitle.toLowerCase().includes(searchTerm.toLowerCase())
                || comboTitle && comboTitle.toLowerCase().includes(searchTerm.toLowerCase())
        })
        return filteredProjects && filteredProjects.length
            ? filteredProjects
            : []
    }

    onItemClicked = (e, image) => {
        const {projectid, comboid, groupid} = image
        if(comboid) {
            this.setProjectId(comboid, POST_TYPE.COMBO_PROJECT)
        }
        else if(groupid) {
            this.setProjectId(groupid, POST_TYPE.GROUP_PROJECT)
        }
        else {
            this.setProjectId(projectid, POST_TYPE.PROJECT)
        }
    }

    renderSearchResults = (searchResult) => {
        const collageImages = getCollageOfTitleImages(searchResult)
        let targetRatios = {'small': 2, 'medium': 4, 'large': 6, 'xlarge': 8}
        if(collageImages.length < 10) {
            targetRatios = {'small': 2, 'medium': 3, 'large': 4, 'xlarge': 6}
        }

        return (
            <ImageCollage
                images = {collageImages}
                onClick={ this.onItemClicked }
                showTitles={true}
                targetRatios= {targetRatios}
            />
        )
    }

	render() {
        const { searchTerm } = this.props
        const {selectedProjectId, selectedProjectType} = this.state
        let path

        if(selectedProjectId) {
            switch(selectedProjectType) {
                case POST_TYPE.COMBO_PROJECT:
                    path = ROUTE_PATH.COMBO.path
                    break;
                case POST_TYPE.GROUP_PROJECT:
                    path = ROUTE_PATH.GROUP.path
                    break;
                default:
                    path = ROUTE_PATH.PROJECT.path
            }
            return <Redirect push to={`${path}/${selectedProjectId}`} />
        }
        if(!searchTerm.length) return null

        const searchResult = [...this.searchProjects()]
		return (
            <div className="search-results">
                <div className="scrollArea">
                    <div className="content-wrapper content-wrapper--full-width">
                        <h1 className="page-title --no-line">
                            <div><strong>{searchResult.length}</strong> Results found...</div>
                        </h1>
                        {this.renderSearchResults(searchResult)}
                    </div>
                </div>
            </div>
		)
	}
}

SearchResults.propTypes = {
    searchTerm: PropTypes.string
}

function mapStateToProps(state) {
    return {
        projects: state.projects.projects,
        comboProjects: state.comboProjects.projects,
        groupProjects: state.groupProjects.projects,
        searchTerm: state.ui.searchTerm
    }
}

export default connect(mapStateToProps, {
    performUpdateSearchTerm,
    performClearSearchTerm,
    performSetCurrentPage
})(SearchResults);
