import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink } from "react-router-dom"
import {performSetCurrentPage} from '../../actions/action_ui'
import './SiteNav.css'
import {
    getVenueAddressString,
    getVenueAddress
} from '../../utils'
import {ROUTE_PATH, getRouteBySlug} from '../../constants/global'
import {Menu} from '../CommonPageElement'

class SiteNav extends Component {
	constructor(props){
		super(props)
	}

	render() {
		const {location:{pathname}} = this.props
		return (
			<Fragment>
				<div id="site-nav" className={`site-nav${pathname === '/' ? ' --hidden' : ''}`}>
					<div className="content-wrapper">
						<Menu className="site-nav__menu" />
					</div>
				</div>
			</Fragment>
		)
	}
}

SiteNav.propTypes = {
	onLoadComplete: PropTypes.func,
}

SiteNav.defaultProps = {}

function mapStateToProps(state) {
  return {
  	homePage: state.pages.pages.find( page => page.slug === 'home'),
  	contactPage: state.pages.pages.find( page => page.slug === 'contact'),
  	currentPage: state.ui.currentPage,
  	currentCategory: state.ui.currentCategory
  }
}

export default connect(mapStateToProps, {
	performSetCurrentPage
})(SiteNav);
