import {
    DEFAULT_PROJECT_IMAGE,
    BREAKPOINT
} from './constants/global'
import moment from 'moment'
import rootReducer from './reducers'

function shapeWordpressProject(project, venues) {
    const { id, title, acf, acf:{projectLocation} } = project

    let projectAddress = {
        title: projectLocation.projectEventTitle,
        ...projectLocation.projectManualAddress,
        addressString: getVenueAddressString(projectLocation.projectManualAddress),
    }
    if(projectLocation.projectLocationFromVenue) {
        const venueId = projectLocation.projectVenue
        const venue = getVenueById(venues, venueId)
        projectAddress = {
            title: venue.title,
            ...venue.venueAddress,
            addressString: getVenueAddressString(venue.venueAddress),
            contactDetails: {
                website: venue.venuWebsite,
                email: venue.venuEmail,
                telephone: venue.venuTelephone
            }
        }
    }
    let {heroImage, projectImages} = acf
    if(heroImage) {
        heroImage = heroImage.map( img => {
            return {
                ...img,
                projectTaxonomy: project.projectTaxonomy
            }
        })
    }
    if(projectImages) {
        projectImages = projectImages.map( img => {
            return {
                ...img,
                projectTaxonomy: project.projectTaxonomy
            }
        })
    }
    return {
        id,
        ...acf,
        projectDateRange: () => {
            const startDate = acf.projectDateRange.projectDateRange.projectStart
            const endDate = acf.projectDateRange.projectDateRange.projectEnd
            return {
                startDate: {
                    date: Number.isNumber(startDate.startDate) ? startDate.startDate : '',
                    month: startDate.startMonth !== "--" ? startDate.startMonth : '',
                    year: Number.isNumber(startDate.startYear) ? startDate.startYear : ''
                },
                endDate: {
                    date: Number.isNumber(endDate.endDate) ? endDate.endDate : '',
                    month: endDate.endMonth !== "--" ? endDate.endMonth : '',
                    year: Number.isNumber(endDate.endYear) ? endDate.endYear : ''
                }
            }
        },
        heroImage: heroImage,
        projectImages: projectImages,
        // projectTitle: title.rendered,
        projectAddress: projectAddress
    }
}

function shapeWordpressVenue(venue) {
    const { id, acf } = venue
    return { 
        title: venue.title.rendered,
        id,
        ...acf
    }
}

function shapeWordpressPage(page) {
    const {
        id,
        slug,
        link,
        title,
        content,
        acf
    } = page

    switch(slug) {
        case 'home':
            return {
                id,
                slug,
                link,
                title: title.rendered,
                introduction: content.rendered,
                bgImage: acf.backgroundImage,
                menu: acf.mainMenu
            }
        case 'contact':
            return {
                id,
                slug,
                link,
                title: title.rendered,
                introduction: content.rendered,
                mainAddress: acf.mainAddress,
                galleryAddress: acf.galleryAddress,
                socialMediaLinks: acf.socialMediaLinks
            }
        case 'news':
            return {
                id,
                slug,
                link,
                title: title.rendered,
                pageTitle: acf.pageTitle,
                articles: acf.articles
            }
        case 'about':
            return {
                id,
                slug,
                link,
                title: title.rendered,
                content: acf
            }
        case 'cv':
            return {
                id,
                slug,
                link,
                title: acf.title,
                subtitle: acf.subtitle,
                content: acf.entries
            }
        case 'privacy':
            return {
                id,
                slug,
                link,
                title: title.rendered,
                content: content.rendered
            }
    }
}

function shapeWordpressCategory(category) {
    const {
        id,
        slug,
        name
    } = category

    return {
        id,
        slug,
        name
    }
}

function shapeWordpressMedia(media) {
  const { description:{rendered}, media_type } = media
  if(media_type === 'image'){
    const startPos = rendered.indexOf('Dominant:') + 10
    const dominantColour = rendered.substring(startPos, startPos+7)
    return { ...media, dominantColour: dominantColour}
  }
  return { ...media }
}

function shapeWordpressGroup(group) {
    const {id, name, slug} = group
  return { id, name, slug }
}

function shapeWordpressNews(news) {
    const {
        id,
        title,
        content,
        acf
    } = news
    return {
        id,
        title: title.rendered,
        content: content.rendered,
        ...acf
    }
}

function getProjectCategory(project) {
  const {name, slug, term_id} = project.projectTaxonomy.projectCategory
  return {
    id: name,
    term_id,
    name
  }
}

// function getPageById(pageId, pages) {
//   return pages.find( page => page.slug === pageId)
// }

function getVenueAddressString(address) {
  const {
    venueAddress1,
    venueAddress2,
    venueAddress3,
    venueTown,
    venuePostcode,
    venueCounty,
    venueCountry
  } = address
  const orderedAddress = [
    venueAddress1,
    venueAddress2,
    venueAddress3,
    venueTown,
    venuePostcode,
    venueCounty,
    venueCountry
  ]
  return orderedAddress.filter( addressField => addressField.length).join(', ')
}

function getVenueAddress(address) {
  const {
    venueAddress1,
    venueAddress2,
    venueAddress3,
    venueTown,
    venuePostcode,
    venueCounty,
    venueCountry
  } = address
  return [
    venueAddress1,
    venueAddress2,
    venueAddress3,
    venueTown,
    venuePostcode,
    venueCounty,
    venueCountry
  ].filter( field => field.length)
}

function getRandomHexColour(options) {
    const {min=0, max=16, greyscale=false} = options
    const whiteInt = 16777215
    const range = max-min
    if(greyscale) {
        const randHex1 = Math.floor((Math.random()*range)+min).toString(16)
        const randHex2 = Math.floor((Math.random()*range)+min).toString(16)
        return `#${randHex1}${randHex2}${randHex1}${randHex2}${randHex1}${randHex2}`
    }
    const hexMin = min <= 0 ? 0 : ((min/16)*whiteInt)
    const hexMax = max <= 0 ? 0 : ((max/16)*whiteInt)
    const hexRange = hexMax - hexMin 
    return `#${Math.floor((Math.random()*hexRange)+hexMin).toString(16)}`
}

function getMediaById(mediaList, id) {
    return mediaList.filter( media => media.id === id )[0]
}

function getProjectImageAspect(projectImage) {
    let ratio = 1
    if(projectImage && projectImage.width && projectImage.height) {
        ratio = projectImage.width / projectImage.height
    }
    return {
        defaultRatio: DEFAULT_PROJECT_IMAGE.ratio,
        ratio: ratio,
        orientation: ratio > 1 ? 'landscape' : ratio < 1 ? 'portrait' : 'square'
    }
}

function getCollageImageObject(image, date = null) {
    return {
        src: image.url,
        width: image.width,
        height: image.height,
        sizes: image.sizes,
        thumbnailSrc: image.sizes.large,
        thumbnailWidth: image.sizes['large-width'],
        thumbnailHeight: image.sizes['large-height'],
        imageid: image.id || image.ID,
        mime_type: image.mime_type,
        type: image.type || image.mime_type.split('/')[0],
        subtype: image.subtype || image.mime_type.split('/')[1],
        description: image.description || "",
        date: date,
        ...getProjectImageAspect(image)
    }
}

function getCollageOfTitleImages(projects) {
    if(!(projects && projects.length)) {
        return []
    }
    return projects.map( project => {
        if(project.type && project.type === 'comboprojects' || project.type === 'groupprojects') {
            const {
                titleImage
            } = project.acf
            return {
                ...getCollageImageObject(
                    project.acf.titleImage,
                    project.acf.date
                ),
                projectid: project.id,
                projectTitle: project.title.rendered,
                projectTaxonomy: project.acf.groupTaxonomy || project.acf.comboTaxonomy
            }
        }
        if(!project.projectDates.projectStartDate.length){
            console.error(`NO START DATE: ${project.projectTitle}`)
        }
        const titleImage = project.heroImage ? project.heroImage[0] : project.projectImages[0]
        return {
            ...getCollageImageObject(titleImage, project.projectDates.projectStartDate),
            projectid: project.id,
            projectTitle: project.projectTitle,
            projectTaxonomy: project.projectTaxonomy
        }
    })
}

function getCollageOfProjectImages(project) {
    if(!(project && project.projectImages && project.projectImages.length)) {
        return []
    }
    return project.projectImages.map( image => {
        return {
            ...getCollageImageObject(image),
            projectid: project.id,
            projectTitle: project.projectTitle,
            projectTaxonomy: project.projectTaxonomy
        }
    })
}

function getProjectById(projects, projectId){
    return projects.find( project => parseInt(project.id,10) === parseInt(projectId,10) )
}

function getProjectsByCategory(projects, category){
    return projects.filter( project => {
        return project.projectTaxonomy.projectCategory.slug === category
    })
}

function getProjectsByGroup(projects, group){
    const groupProjects = []
    projects.forEach( project => {
        const groupName = project.projectTaxonomy.projectGroup.projectGroupName
        if(groupName && groupName.slug === group) {
            groupProjects.push(project)
        }
    })
    return groupProjects
}

function getGroupById(groups, groupId){
    return groups.find( group => group.id === groupId || group.slug === groupId )
}

function getVenueById(venues, venueId){
    return venues.find( venue => venue.id === venueId )
}

function getCapitalisedString(str) {
    return `${str.charAt(0).toUpperCase()}${str.substring(1)}`
}

function getProjectDate(sDate, eDate) {
    moment.locale('en-UK')
    const startdate = sDate && sDate !== ''
        ? moment(sDate)
        : null
    const enddate = eDate && eDate !== ''
        ? moment(eDate)
        : null
    const yearOnly = 'YYYY'
    const fullDate = 'MMMM Do YYYY'

    if(!startdate && !enddate) {
        return null
    }
    if(enddate){
        if(moment().diff(enddate,'months') >= 12) {
            return enddate.format(yearOnly)
        }
        else if(startdate) {
            return `${startdate.format(fullDate)} - ${enddate.format(fullDate)}`
        }
        return enddate.format(fullDate)
    }
    if(startdate) {
        if(moment().diff(startdate,'months') >= 12) {
            return startdate.format(yearOnly)
        }
        return startdate.format(fullDate)
    }
}

function getBreakpoint(width) {
    return Object.keys(BREAKPOINT).find( name => {
        const bp = BREAKPOINT[name]
        return width >= bp.min && width <= bp.max
    })
}

function createDOMElement(type = 'div', props = {}) {
    const $el = document.createElement(type)
    return Object.assign($el, props)
}

function getFiletype (src) {
    const imagetypes = ['jpg','jpeg','bmp','png','gif','svg']
    const videotypes = ['webm','mp4','ogg','mpeg','wav']
    let filetype = 'unknown'
    const ext = src.split('.').pop().toLowerCase()

    if(imagetypes.includes(ext)){
        filetype = 'image'
    }
    if(videotypes.includes(ext)){
        filetype = 'video'
    }
    return {filetype, ext}
}

function getImgSrcSet(data) {
    return data.reduce( (_srcset, item) => {
        const srcset = _srcset === '' ? '' : `${_srcset}, `
        return `${srcset}${item.src} ${item.descriptor}`
    }, '')
}

function getTruncatedText(text, charSize, lineWidth, letterSpacing = .5) {
    const maxChars = Math.floor(lineWidth/(charSize+letterSpacing))
    if(text.length <= maxChars) {
        return text
    }
    return `${text.slice(0, maxChars-3).trimEnd()}...`
}

const scrollPos = {
    get: ($el) => {
        return $el.scrollTop
    },
    to: ($el, pos) => {
        $el.scrollTop(pos)
    }
}

export {
    shapeWordpressProject,
    shapeWordpressVenue,
    shapeWordpressPage,
    shapeWordpressCategory,
    shapeWordpressMedia,
    shapeWordpressGroup,
    shapeWordpressNews,
    getProjectImageAspect,
    getProjectCategory,
    getGroupById,
    getVenueById,
    getVenueAddressString,
    getVenueAddress,
    getRandomHexColour,
    getMediaById,
    getCollageImageObject,
    getCollageOfTitleImages,
    getCollageOfProjectImages,
    getProjectById,
    getProjectsByCategory,
    getProjectsByGroup,
    getCapitalisedString,
    getProjectDate,
    getBreakpoint,
    createDOMElement,
    getFiletype,
    getImgSrcSet,
    getTruncatedText
}