import React, {Component} from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

class ProjectMaterials extends Component {
	render() {
		const {
			className,
			defaultClassName,
			project:{materialsAndSize}
		} = this.props
		const parsedClass = className && className.length ? ` ${className}` : ''

		if(!materialsAndSize) {
			return null
		}
		const materials = materialsAndSize.materials
		const size = materialsAndSize.size
		if(!(materials && materials.length) && !(size && size.length)) {
			return null
		}

		return (
			<div className={`${defaultClassName}${parsedClass}`}>
				{	materials !== ''
					&& <div className={`${defaultClassName}__detail`}
						dangerouslySetInnerHTML={{__html: materials}}>
					</div>
				}
				{	size !== ''
					&& <div className={`${defaultClassName}__size`}>{size}</div>
				}
			</div>
		)
	}
}

ProjectMaterials.defaultProps = {
	defaultClassName: 'common-project-materials'
}

ProjectMaterials.propTypes = {
	project: PropTypes.object.isRequired
}

function mapStateToProps(state) {
	return {
		venues: state.projects.venues
	}
}

export default connect(mapStateToProps, null)(ProjectMaterials);
