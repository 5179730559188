import {
	TOGGLE_GALLERY_FORMAT,
	TOGGLE_MAIN_MENU,
	UPDATE_SEARCH_TERM,
	CLEAR_SEARCH_TERM,
	UPDATE_SCREEN_PROPS,
	SET_CURRENT_PAGE,
	RESTORE_PREV_SEARCH_TERM
} from '../constants/actionTypes'

import { getBreakpoint } from '../utils'

const initialState = {
	galleryFormat: 'collage',
	showMainMenu: false,
	currentPage: 'home',
	prevPage: null,
	currentCategory: null,
	prevCategory: null,
	pageHistory: [],
	categoryHistory: [],
	searchTerm: '',
	prevSearchTerm: '',
	screenProps: {
		breakpoint: {
			current: '',
			previous: ''
		},
		page: {},
		header: {},
		screen: {}
	}
}

function toggleGalleryFormat(state, action){
	const {format} = action
	const newFormat = format === undefined
		? state.galleryFormat === 'grid'
			? 'collage'
			: 'grid'
		: format
	return {
		...state,
		galleryFormat: newFormat
	}
}

function toggleMainMenu(state, action){
	const {show} = action
	const showMainMenu = show === undefined
		? state.showMainMenu
			? false
			: true
		: show
	return {
		...state,
		showMainMenu: showMainMenu
	}
}

function processScreenProps(props, state){
	const {bounds} = props
	return {
		breakpoint: {
			current: getBreakpoint(bounds.width),
			previous: state.screenProps.breakpoint.current
		},
		page: {
			bounds: bounds
		},
		header: {
			bounds: {
				...bounds,
				top: 0,
				bottom: bounds.top,
				height: bounds.top
			}
		},
		screen: {
			bounds: {
				...bounds,
				top: 0,
				bottom: bounds.height + bounds.top,
				height: bounds.height + bounds.top
			}
		}
	}
}

export default function UIReducer(state = initialState, action) {
	switch (action.type) {
		case TOGGLE_GALLERY_FORMAT:
			return toggleGalleryFormat(state, action)

		case TOGGLE_MAIN_MENU:
			return toggleMainMenu(state, action)

		case UPDATE_SEARCH_TERM:
			return {
				...state,
				searchTerm: action.searchTerm
			}

		case CLEAR_SEARCH_TERM:
			return {
				...state,
				prevSearchTerm: action.updatePrevSearchTerm
					? state.searchTerm
					: state.prevSearchTerm,
				searchTerm: ''
			}

		case RESTORE_PREV_SEARCH_TERM:
			return {
				...state,
				prevSearchTerm: '',
				searchTerm: state.prevSearchTerm
			}

		case UPDATE_SCREEN_PROPS:
			return {
				...state,
				screenProps: processScreenProps(action.props, state)
			}

		case SET_CURRENT_PAGE:
			const pageHistory = [...state.pageHistory]
			pageHistory.unshift(state.currentPage)
			const categoryHistory = [...state.categoryHistory]
			categoryHistory.unshift(state.currentCategory)
			return {
				...state,
				currentPage: action.pageId,
				currentCategory: action.categoryId,
				prevPage: state.currentPage,
				prevCategory: state.currentCategory,
				pageHistory: pageHistory.slice(0,2),
				categoryHistory: categoryHistory.slice(0,2)
			}
			
		default:
			return state
	}
}
