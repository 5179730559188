// See examples here: http://www.davidhu.io/react-spinners/

import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import { ClipLoader, BarLoader, CircleLoader, BounceLoader } from 'react-spinners'
import './Loader.css'

const override = css`
    display: block;
    margin: 0 auto;
`;

class Loader extends Component {
	constructor(props){
		super(props)
		this.state = {
	      loading: true
	    }
	}

	renderLoader = () => {
		const {type, colour} = this.props
  		switch (type) {
  			case 'CircleLoader':
				return (
			        <div className="loading-animation__spinner">
				  		<CircleLoader
				          sizeUnit={"px"}
				          size={50}
				          color={colour}
				          loading={this.state.loading}
			        	/>
			        </div>
			    )
			    break
  			case 'BarLoader':
				return (
			        <div className="loading-animation__spinner">
				  		<BarLoader
				          sizeUnit={"px"}
				          width={200}
				          height={4}
				          color={colour}
			          	  loading={this.state.loading}
			        	/>
			        </div>
			    )
			    break
  			case 'BounceLoader':
				return (
			        <div className="loading-animation__spinner">
				  		<BounceLoader
				          sizeUnit={"px"}
				          size={50}
				          color={colour}
				          loading={this.state.loading}
			        	/>
			        </div>
			    )
			    break
  			case 'ClipLoader':
				return (
			        <div className="loading-animation__spinner">
				  		<ClipLoader
				          sizeUnit={"px"}
				          size={50}
				          color={colour}
				          loading={this.state.loading}
			        	/>
			        </div>
			    )
			    break
  			default:
  				return null
		}
	}

	render() {
		const {message} = this.props
		return (
		    <div className='loading-animation'>
				{this.renderLoader()}
				{ message &&
					<div className="loading-animation__message">{message}</div>
				}
		    </div>
		)
	}
}

Loader.defaultProps = {
	type: 'CircleLoader',
	loading: true,
	colour: '#000000',
	className: ''
}

Loader.propTypes = {
	type: PropTypes.string,
	loading: PropTypes.bool,
	colour: PropTypes.string,
	className: PropTypes.string
}

export default Loader;