import {
	FETCH_PROJECTS_STARTED,
	FETCH_PROJECTS_COMPLETED,
	FETCH_COMBO_PROJECTS_STARTED,
	FETCH_COMBO_PROJECTS_COMPLETED,
	FETCH_GROUP_PROJECTS_STARTED,
	FETCH_GROUP_PROJECTS_COMPLETED,
	FETCH_PROJECT_BY_ID,
	GET_PROJECTS_BY_CATEGORY,
	SET_CURRENT_PROJECT,
	FETCH_VENUES,
	FETCH_VENUE_BY_ID,
	GET_VENUES,
	SEARCH_PROJECTS_BY_TITLE,
	FETCH_MEDIA,
	FETCH_GROUPS
} from '../constants/actionTypes'

import {
	fetchProjects,
	fetchComboProjects,
	fetchGroupProjects,
	fetchVenues,
	fetchMedia,
	fetchGroups
} from '../services/wordpressAPI'

export function fetchProjectsStarted() {
	return {
		type: FETCH_PROJECTS_STARTED
	}
}

export function fetchProjectsCompleted(payload) {
	return {
		type: FETCH_PROJECTS_COMPLETED,
		payload
	}
}

export function fetchComboProjectsStarted() {
	return {
		type: FETCH_COMBO_PROJECTS_STARTED
	}
}

export function fetchComboProjectsCompleted(payload) {
	return {
		type: FETCH_COMBO_PROJECTS_COMPLETED,
		payload
	}
}

export function fetchGroupProjectsStarted() {
	return {
		type: FETCH_GROUP_PROJECTS_STARTED
	}
}

export function fetchGroupProjectsCompleted(payload) {
	return {
		type: FETCH_GROUP_PROJECTS_COMPLETED,
		payload
	}
}

export function performFetchProjects() {
	return dispatch => {
		dispatch(fetchProjectsStarted())
		return fetchProjects()
			.then(response => dispatch(fetchProjectsCompleted(response)))
	}
}

export function performFetchComboProjects() {
	return dispatch => {
		dispatch(fetchComboProjectsStarted())
		return fetchComboProjects()
			.then(response => dispatch(fetchComboProjectsCompleted(response)))
	}
}

export function performFetchGroupProjects() {
	return dispatch => {
		dispatch(fetchGroupProjectsStarted())
		return fetchGroupProjects()
			.then(response => dispatch(fetchGroupProjectsCompleted(response)))
	}
}

export function performSetCurrentProject(id) {
	return {
		type: SET_CURRENT_PROJECT,
		id
	}
}

export function performGetProjectsByCategory(category) {
	return {
		type: GET_PROJECTS_BY_CATEGORY,
		category
	}
}

export function performSearchProjectsByTitle(searchterm) {
	return {
		type: SEARCH_PROJECTS_BY_TITLE,
		searchterm
	}
}

export function performFetchVenues() {
	const request = fetchVenues()
	return {
		type: FETCH_VENUES,
		payload: request
	}
}

export function performGetVenues() {
	return {
		type: GET_VENUES,
		payload: []
	}
}

export function performFetchMedia() {
	const request = fetchMedia()
	return {
		type: FETCH_MEDIA,
		payload: request
	}
}

export function performFetchGroups() {
	const request = fetchGroups()
	return {
		type: FETCH_GROUPS,
		payload: request
	}
}