import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { NavLink } from "react-router-dom"
import {performSetCurrentPage} from '../../../actions/action_ui'
import {
    getVenueAddressString,
    getVenueAddress
} from '../../../utils'
import {ROUTE_PATH, getRouteBySlug} from '../../../constants/global'

class HomePageMenu extends Component {
	constructor(props){
		super(props)
		this.state = {
			currentSelection: null,
			menuData: this.initMenuData()
		}
	}

	initMenuData = () => {
		const {homePage:{menu}} = this.props
		const menuData = Object.keys(menu).map( id => {
			const item = menu[id]
			return {...item, id}
		})

		menuData.sort( (a,b) => {
			return a.position - b.position
		})

		return menuData
	}

	setCurrentSelection = (selection) => {
		const {currentSelection} = this.state
		// if(selection !== currentSelection) {
			this.setState({
				currentSelection: selection
			})
		// }
	}

	renderMenu = () => {
		const {menuData} = this.state
		const {pathname} = this.props
		let path = 'home'
		let subpath = null
		const pathSections = pathname.split('/').filter( section => section !== "")
		if(pathSections.length) {
			path = pathSections[0]
			subpath = pathSections[1]
		}

		console.log(menuData);

		return (
			<Fragment>
				{
					menuData.map( (item) => {
						const {title, subTitle, id, type} = item
						const linkPath = type === 'category'
							? `${getRouteBySlug('category').path}/${id}`
							: `${getRouteBySlug(id).path}`
						const activeCls = id === pathname
							? ' --active'
							: ''
						const style = {
							backgroundImage: `url(${item.background_image})`
						}
						return (
							<div
								className={`home-page__menu__item --${id}${activeCls}`}
								key={`home-page__menu-link-${id}`}
								style={style}
							>
								<NavLink to={linkPath}>{subTitle}</NavLink>
							</div>
						)
					})
				}
			</Fragment>
		)
	}

	render() {
		return (
			<div className="home-page__menu">
		        {this.renderMenu()}
			</div>
		)
	}
}

HomePageMenu.propTypes = {
	onLoadComplete: PropTypes.func,
	pathname: PropTypes.string
}

HomePageMenu.defaultProps = {
	pathname: '/'
}

function mapStateToProps(state) {
  return {
  	homePage: state.pages.pages.find( page => page.slug === 'home')
  }
}

export default connect(mapStateToProps, {
	performSetCurrentPage
})(HomePageMenu);
