import React, {Component} from 'react'
import PropTypes from 'prop-types'

class ProjectDescription extends Component {
    render() {
          const {
               project:{projectDescription},
               className,
               defaultClassName
          } = this.props
          const parsedClass = className && className.length ? ` ${className}` : ''

          if(!(projectDescription && projectDescription.length)) {
               return null
          }
          return (
               <div className={`${defaultClassName}${parsedClass}`}>
                    <div className={`${defaultClassName}__detail`}
                         dangerouslySetInnerHTML={{__html: projectDescription}}
                    ></div>
               </div>
          )
    }
}

ProjectDescription.defaultProps = {
    defaultClassName: 'common-project-description'
}

ProjectDescription.propTypes = {
    project: PropTypes.object.isRequired
}

export default ProjectDescription;