import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types';
import {
    performSetCurrentProject
} from '../../actions/action_project'
import {performSetCurrentPage} from '../../actions/action_ui'
import {ImageViewer} from '../ImageViewer'
import {
    getVenueAddressString,
    getVenueAddress,
    getCollageOfProjectImages,
    getVenueById,
    getProjectDate,
    shapeWordpressProject
} from '../../utils'
import './ComboProject.css'
import {
    BackToSearch,
    PageTitle,
    ProjectDescription,
    ProjectLocation
} from '../CommonPageElement'
import ComboProjectItem from './Widget/ComboProjectItem'

class ComboProject extends Component {
    constructor(props){
        super(props)
        this.state = {
            selectedImageId: null,
            comboProject: null,
            projects: null
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {comboSlug} = nextProps.match.params
        const {
            comboProjects,
            allProjects,
            venues,
            currentPage,
            performSetCurrentPage
        } = nextProps
        const comboProject = comboProjects.find( project => project.slug === comboSlug)
        const comboId = comboProject.id
        const projects = allProjects.filter( project => {
            const {projectComboName} = project.projectTaxonomy.projectCombo
            return projectComboName && projectComboName.includes(comboId)
        })
        projects.sort( (p1, p2) => {
            return p1.projectTitle.localeCompare(p2.projectTitle)
        })
        const images = projects.map( project => project.projectImages ).flat()
        if(currentPage !== 'combo') {
            const category = comboProject.acf.comboTaxonomy.category.slug
            performSetCurrentPage('combo', category)
        }
        return {
            ...prevState,
            comboProject,
            projects,
            images
        }
    }

    setSelectedImageId = (selectedImageId) => {
        const {projects} = this.state
        this.setState({
            selectedImageId
        })
    }

    onCollageImageClicked = (e, obj) => {
        const {imageid} = obj
        this.setSelectedImageId(imageid)
    }

    closeCarousel = () => {
        this.setSelectedImageId(null)
    }

    renderSingleProject = (project) => {
        return <ComboProjectItem
            key={`combo-project-item-${project.id}`}
            project={project}
            onCollageImageClicked={this.onCollageImageClicked}
        />
    }

    renderProjects = () => {
        const {projects} = this.state
        return (
            <div className="combo-project-page__projects">
             { projects.map( project => this.renderSingleProject(project)) }
            </div>
        )
    }

    renderBackToSearch = () => {
        const {prevSearchTerm} = this.props
        if(prevSearchTerm.length) {
            return <BackToSearch />
        }
        return null
    }

    renderProjectInfo = () => {
        const {comboProject} = this.state
        const projectDescription = comboProject.acf.description
        return (
            <div className="content-wrapper">
                <div className="combo-project-page_info">
                    <PageTitle className="combo-project-page__info__title">
                        {comboProject.title.rendered}
                    </PageTitle>
                    <ProjectDescription className="combo-project-page__info__description"
                                        project={{projectDescription: projectDescription}} />
                </div>
            </div>
        )
    }

    renderCarousel = () => {
        const {selectedImageId, projects} = this.state
        if(!selectedImageId) {
            return null
        }
        const images = projects.map( p => getCollageOfProjectImages(p) ).flat()
        return (
            <ImageViewer
                selectedImageId={selectedImageId}
                images={images}
                onCloseClicked={this.closeCarousel}
            />
        )
    }

    render() {
        return (
            <div className="scrollArea">
                <main className="combo-page">
                    {this.renderBackToSearch()}
                    {this.renderProjectInfo()}
                    <div className="content-wrapper">
                        {this.renderProjects()}
                    </div>
                </main>
                { this.renderCarousel() }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        currentCategory: state.ui.currentCategory,
        currentPage: state.ui.currentPage,
        prevSearchTerm: state.ui.prevSearchTerm,
        comboProjects: state.comboProjects.projects,
        allProjects: state.projects.projects,
        venues: state.projects.venues
    }
}

export default connect(mapStateToProps, {
    performSetCurrentProject,
    performSetCurrentPage
})(ComboProject);