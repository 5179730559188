import {
	FETCH_GROUP_PROJECTS_STARTED,
	FETCH_GROUP_PROJECTS_COMPLETED
} from '../constants/actionTypes'
import {
	shapeWordpressProject,
	shapeWordpressVenue,
	shapeWordpressMedia,
	shapeWordpressGroup
} from '../utils'
import { LOADING_STATE } from '../constants/global'
import moment from 'moment' 

const initialState = {
	projects: [],
	loading: LOADING_STATE.IDLE
}

function fetchGroupProjectsStarted(state, action){
	return {
		...state,
		loading: LOADING_STATE.STARTED
	}
}

function fetchGroupProjectsCompleted(state, action){
	return {
		...state,
		loading: LOADING_STATE.COMPLETED,
		projects: action.payload.data
	}
}

export default function GroupProjectsReducer(state = initialState, action) {
	switch (action.type) {

		case FETCH_GROUP_PROJECTS_STARTED:
			return fetchGroupProjectsStarted(state, action)

		case FETCH_GROUP_PROJECTS_COMPLETED:
			return fetchGroupProjectsCompleted(state, action)
			
		default:
			return state
	}
}
