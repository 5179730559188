import {
	FETCH_COMBO_PROJECTS_STARTED,
	FETCH_COMBO_PROJECTS_COMPLETED
} from '../constants/actionTypes'

import {fetchComboProjects} from '../services/wordpressAPI'

export function fetchComboProjectsStarted() {
	return {
		type: FETCH_COMBO_PROJECTS_STARTED
	}
}

export function fetchComboProjectsCompleted(payload) {
	return {
		type: FETCH_COMBO_PROJECTS_COMPLETED,
		payload
	}
}

export function performFetchComboProjects() {
	return dispatch => {
		dispatch(fetchComboProjectsStarted())
		return fetchComboProjects()
			.then(response => dispatch(fetchComboProjectsCompleted(response)))
	}
}