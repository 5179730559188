import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {performRestorePrevSearchTerm} from '../../../actions/action_ui'

class BackToSearch extends Component {

    restorePrevSearch = () => {
        this.props.performRestorePrevSearchTerm()
    }

    render() {
        // return <Link className="" to={'/'}>&lt; Back to Search Results</Link>
        return (
            <div className="back-to-search">
                <div className="content-wrapper">
                    <div className="back-to-search__link"
                         onClick={this.restorePrevSearch}
                    ><span>&lt;</span> Back to Search Results</div>
                </div>
            </div>
        )
    }
}

BackToSearch.defaultProps = {
}

BackToSearch.propTypes = {
}

function mapStateToProps(state) {
  return {}
}

export default connect(mapStateToProps, {
    performRestorePrevSearchTerm
})(BackToSearch);