
import history from './history'
import {createStore, applyMiddleware, compose} from 'redux'
import ReduxPromise from 'redux-promise'
import thunkMiddleware from 'redux-thunk'
import rootReducer from './reducers'

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    })
  : compose;

const enhancer = composeEnhancers(
  applyMiddleware(
    ReduxPromise,
    thunkMiddleware
  // other store enhancers if any
  )
);

// const enhancer = applyMiddleware(
//   ReduxPromise,
//   thunkMiddleware
// )

export const store = createStore(rootReducer, enhancer);
