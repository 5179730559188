import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types';
import {
    performSetCurrentProject
} from '../../actions/action_project'
import {performSetCurrentPage} from '../../actions/action_ui'
import {ImageViewer} from '../ImageViewer'
import {
    getCollageOfProjectImages,
    getProjectDate,
    getProjectById
} from '../../utils'
import {ImageCollage, IMAGE_COLLAGE} from '../ImageCollage'
import './Project.css'
import {
    BackToSearch,
    ProjectDescription,
    ProjectLocation,
    ProjectMaterials,
    ProjectDate
} from '../CommonPageElement'

class Project extends Component {
    constructor(props){
        super(props)
        this.state = {
            selectedImageId: null,
            projectId: null,
            project: null
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        const {projectId} = nextProps.match.params
        const {
            projects,
            currentPage,
            performSetCurrentPage
        } = nextProps
        const project = getProjectById(projects, projectId)
        if(currentPage !== 'project') {
            const category = project.projectTaxonomy.projectCategory.slug
            performSetCurrentPage('project', category)
        }
        return {
            ...prevState,
            projectId,
            project
        }
    }

    setSelectedImageId = (id) => {
        this.setState({
            selectedImageId: id
        })
    }

    onCollageImageClicked = (e, obj) => {
        const {imageid} = obj
        this.setSelectedImageId(imageid)
    }

    closeCarousel = () => {
        this.setSelectedImageId(null)
    }

    renderBackToSearch = () => {
        const {prevSearchTerm} = this.props
        if(prevSearchTerm.length) {
            return <BackToSearch />
        }
        return null
    }

    renderProjectInfo = () => {
        const {
            project,
            project:{projectDescription}
        } = this.state
        const hasDescription = projectDescription && projectDescription.length
        const classes = hasDescription ? '' : ' --no-description'
        return (
            <div className="content-wrapper">
                <div className={`project-page__info${classes}`}>
                    <h1 className="project-page__info__title page-title">{project.projectTitle}</h1>
                    <div className="project-page__info__details">
                        <ProjectDescription className="project-page__info__details__description"
                                            project={project} />
                        <ProjectLocation className="project-page__info__details__location"
                                         project={project} />
                        <ProjectDate className="project-page__info__details__date"
                                         project={project} />
                        <ProjectMaterials className="project-page__info__details__materials"
                                          project={project} />
                    </div>
                </div>
            </div>
        )
    }

    renderHeroPanel = () => {
        const {project} = this.state
        if(!project.heroImage) {
            return null
        }
        const heroImage = project.heroImage[0]

        return (
            <div className="project-page__hero-panel">
                { heroImage.type !== 'video' &&
                    <img src={heroImage.url} alt={heroImage.caption} />
                }
                { heroImage.type === 'video' &&
                    <video  controls autoPlay>
                        <source src={heroImage.url} type={heroImage.mime_type} />
                        Sorry, your browser doesn't support embedded videos.
                    </video>
                }
            </div>
        )
    }

    renderCarousel = (images) => {
        const {
            selectedImageId,
            project
        } = this.state
        if(!selectedImageId) {
            return null
        }
        return (
            <ImageViewer
                selectedImageId={selectedImageId}
                images={images}
                onCloseClicked={this.closeCarousel}
            />
        )
    }

    renderCollage = (images) => {
        if(!images.length){
            return null
        }
        return (
            <ImageCollage
                images={images}
                onClick={ this.onCollageImageClicked }
                showGroups={false}
                type={IMAGE_COLLAGE.TYPE.PROJECT}
            />
        )
    }

    render() {
        const {
            selectedImageId,
            project
        } = this.state

        if(!project) {
            return null
        }
        const collageImages = getCollageOfProjectImages(project)
        return (
            <Fragment>
                {this.renderBackToSearch()}
                <div className="scrollArea">
                    <main className="project-page">
                        {this.renderProjectInfo()}
                        <div className="content-wrapper">
                            {this.renderHeroPanel()}
                            {this.renderCollage(collageImages)}
                        </div>
                    </main>
                    { this.renderCarousel(collageImages) }
                </div>
            </Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        projects: state.projects.allProjects,
        currentCategory: state.ui.currentCategory,
        currentPage: state.ui.currentPage,
        prevSearchTerm: state.ui.prevSearchTerm
    }
}

function mapDispatchToProps(dispatch){
    return bindActionCreators({
        performSetCurrentProject,
        performSetCurrentPage
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Project);