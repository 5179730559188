import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import './Privacy.css'
import { performSetCurrentPage } from '../../actions/action_ui'
import {
	PageTitle,
	ScrollArea
} from '../CommonPageElement'
import { ROUTE_PATH } from '../../constants/global'

class Privacy extends Component {
	constructor(props){
		super(props)
		props.performSetCurrentPage('privacy')
	}

	renderTitle = () => {
		const {privacy:{title}} = this.props
		return <PageTitle className="privacy-page__title">{title}</PageTitle>
	}

	renderContent = () => {
		const {privacy:{content}} = this.props
		return (
			<section className="privacy-page__content__policy">
          		<div className="privacy-page__content__policy__text"
					dangerouslySetInnerHTML={{__html: content}}>
				</div>
			</section>
		)
	}

	render() {
		return (
			<ScrollArea className="privacy-page"
					  scrollKey={ROUTE_PATH.PRIVACY.slug}>
				<div className="content-wrapper">
					{ this.renderTitle() }
					<div className="privacy-page__content">
				        {this.renderContent()}
					</div>
				</div>
			</ScrollArea>
		)
	}
}

Privacy.propTypes = {

}

function mapStateToProps(state) {
  return {
  	privacy: state.pages.pages.find( page => {
  		return page.slug === 'privacy'
  	})
  }
}

export default connect(mapStateToProps, {
	performSetCurrentPage
})(Privacy);