import React, {Component} from 'react';
import PropTypes from 'prop-types';

class FlipPanelToggleButton extends Component {
    render() {
        const {onClickHandler} = this.props
        return <div
            className="flip-panel-toggle-button"
            onClick={ e => onClickHandler(e) }
        >more</div>
    }
}

FlipPanelToggleButton.defaultProps = {

}

FlipPanelToggleButton.propTypes = {
    onClickHandler: PropTypes.func.isRequired
}

export default FlipPanelToggleButton