import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import { bindActionCreators } from 'redux'
import SVG from '../SVG/SVG'
import {
    getVenueAddressString,
    getVenueAddress
} from '../../utils'
import './SiteFooter.css'

class SiteFooter extends Component {

	renderContactDetails = () => {
		const {
			contactPage,
			contactPage:{mainAddress},
			contactPage:{galleryAddress},
			contactPage:{socialMediaLinks}
		} = this.props
        const mainAddressFields = getVenueAddress(mainAddress.address)
        const galleryAddressFields = getVenueAddress(galleryAddress.address)
        const hasMainAddress = mainAddressFields.join('').length
        const hasGalleryAddress = galleryAddressFields.join('').length
        return (
        	<Fragment>
	        	{hasMainAddress &&
	    			<div className="site-footer__main-address">
						<div className="site-footer__titl2e">Contact</div>
		        		{mainAddressFields.map( (field, index) => {
		        			return index === 0
		        				? <div key={`main-address-field-${index}`}><strong>{field}</strong></div>
		        				: <div key={`main-address-field-${index}`}>{field}</div>
		        		})}
	        		</div>
	        	}
	        	{hasGalleryAddress &&
	    			<div className="site-footer__gallery-address">
						<div className="site-footer__title">Represented by</div>
		        		{galleryAddressFields.map( (field, index) => {
		        			return index === 0
		        				? <div key={`gallery-address-field-${index}`}><strong>{field}</strong></div>
		        				: <div key={`gallery-address-field-${index}`}>{field}</div>
		        		})}
	        		</div>
	        	}
        	</Fragment>
        )
	}

	render() {
		const {currentPage} = this.props
		const classes = currentPage === 'home' ? 'hide' : ''
		return (
			<footer id="site-footer" className={`site-footer content-wrapper ${classes}`}>
				{this.renderContactDetails()}
				<nav className="site-footer__social">
					<div className="site-footer__title">Connect</div>
					<a className="site-footer__social__link --facebook" target="_blank" href="https://www.facebook.com/heywood.condie">
						{/*<SVG icon="facebook"/>*/}
					</a>
					<a className="site-footer__social__link --twitter" target="_blank" href="https://twitter.com/HeywoodCondie">
						{/*<SVG icon="twitter"/>*/}
					</a>
					<a className="site-footer__social__link --instagram" target="_blank" href="https://www.instagram.com/explore/tags/heywoodandcondie/">
						{/*<SVG icon="instagram"/>*/}
					</a>
					<a className="site-footer__social__link --youtube" target="_blank" href="https://www.youtube.com/user/HeywoodAndCondie/videos?view=0&sort=dd&shelf_id=0">
						{/*<SVG icon="youtube2" />*/}
					</a>
				</nav>
			</footer>
		)
	}
}

SiteFooter.propTypes = {}

function mapStateToProps(state) {
  	const contactPage = state.pages.pages.find( page => page.slug === 'contact')
  	return {
  		contactPage,
  		currentPage: state.pages.currentPage
  	}
}

export default connect(mapStateToProps)(SiteFooter);