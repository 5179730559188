import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import {Redirect} from 'react-router-dom'
import { bindActionCreators } from 'redux'
import {
	PageTitle,
	ScrollArea
} from '../CommonPageElement'
import { performSetCurrentPage } from '../../actions/action_ui'
import {
	getProjectById,
	getCollageOfProjectImages
} from '../../utils'
import './News.css'
import ImageCollage from '../ImageCollage/ImageCollage'
import {ROUTE_PATH} from '../../constants/global'

class News extends Component {
	constructor(props){
		super(props)
		this.state = {
			redirectPath: null
		}
		props.performSetCurrentPage('news')
	}

	onImageClicked = (projectId) => {
		console.log(projectId);
		const redirectPath = `${ROUTE_PATH.PROJECT.path}/${projectId}`
		this.setState({redirectPath})
	}

	renderArticleImage = (image, projectId) => {
		if(projectId) {
			return (
				<div className="news-page__article__image --clickable">
					<img src={image} alt="Article image"
						onClick={ () => this.onImageClicked(projectId) }
					/>
				</div>
			)
		}

		return (
			<div className="news-page__article__image">
				<img src={image} alt="Article image"/>
			</div>
		)
	}

	renderNewsArticles = () => {
		const {articles} = this.props.news
		if(!articles && !articles.length) {return null}
		return articles.map( (article, index) => {
			const {
				title,
				linkedProject,
				articleDetails:{
					image,
					description
				}
			} = article
			return (
				<article key={`news-article-${index}`}
					    className="news-page__article"
				>
					{this.renderArticleImage(image, linkedProject)}
					<div className="news-page__article__info">
						<div className="news-page__article__info__title">
							<h2>{title}</h2>
						</div>
						<div className="news-page__article__info__description"
							dangerouslySetInnerHTML={{__html: description}}
						></div>
					</div>
				</article>
			)
		})
	}

	render() {
		const {
			news:{title},
			news:{pageTitle}
		} = this.props
		const { redirectPath } = this.state
		if(redirectPath) {
			return <Redirect push to={redirectPath} />
		}
		return (
			<ScrollArea scrollKey={ROUTE_PATH.NEWS.slug}>
				<div className="content-wrapper">
					<PageTitle>{pageTitle || title}</PageTitle>
					<div className="news-page">
				        {this.renderNewsArticles()}
					</div>
				</div>
			</ScrollArea>
		)
	}
}

News.propTypes = {

}

function mapStateToProps(state) {
  return {
  	projects: state.projects.projects,
  	news: state.pages.pages.find( page => {
  		return page.slug === 'news'
  	})
  }
}

function mapDispatchToProps(dispatch){
	return bindActionCreators({
		performSetCurrentPage
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(News);