import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
	BrowserRouter as Router,
	HashRouter,
	Redirect,
	Route,
	Switch
} from "react-router-dom";
import {
	performFetchProjects,
	performFetchVenues,
	performFetchMedia,
} from '../actions/action_project'
import { performFetchComboProjects } from '../actions/action_comboProjects'
import { performFetchGroupProjects } from '../actions/action_groupProjects'
import { performFetchPages } from '../actions/action_page'
import { performUpdateScreenProps } from '../actions/action_ui'
import { performFetchCategories } from '../actions/action_category'
import {
	ROUTE_PATH,
	LOADING_STATE
} from '../constants/global'
import SiteHeader from './SiteHeader/SiteHeader'
import SiteFooter from './SiteFooter/SiteFooter'
import Home from './Home/Home'
import Category from './Category/Category'
import About from './About/About'
import CV from './CV/CV'
import Project from './Project/Project'
import ComboProject from './ComboProject/ComboProject'
import GroupProject from './GroupProject/GroupProject'
import News from './News/News'
import Contact from './Contact/Contact'
import Privacy from './Privacy/Privacy'
import SearchResults from './SiteSearch/SearchResults'
import SiteNav from './SiteNav/SiteNav'
import history from '../history'
import Loader from './Loader/Loader'
import './SVG/SVGDefs'
import Measure from 'react-measure'
import ConsentBar from './ConsentBar/ConsentBar'

class App extends Component {
	constructor(props){
		super(props);
		this.state= {
			pagesLoaded: false,
			projectsLoaded: false,
			comboProjectsLoaded: false,
			groupProjectsLoaded: false,
			venuesLoaded: false,
			categoriesLoaded: false,
			mediaLoaded: false,
			siteReady: false
		}
	  	props.performFetchVenues()
	  	props.performFetchPages()
	  	props.performFetchCategories()
	  	props.performFetchComboProjects()
	  	props.performFetchGroupProjects()
	}

	static getDerivedStateFromProps(nextProps, nextState) {
		const {
			performFetchProjects,
			projectsLoading,
			venuesLoading,
			mediaLoading,
			pagesLoading,
			categoriesLoading,
			comboProjectsLoading,
			groupProjectsLoading
		} = nextProps
		if(venuesLoading ===  LOADING_STATE.COMPLETED && projectsLoading === LOADING_STATE.IDLE) {
  			nextProps.performFetchProjects()
		}

		const loadingStates = {
			pagesLoaded: pagesLoading === LOADING_STATE.COMPLETED,
			projectsLoaded: projectsLoading === LOADING_STATE.COMPLETED,
			venuesLoaded: venuesLoading === LOADING_STATE.COMPLETED,
			categoriesLoaded: categoriesLoading === LOADING_STATE.COMPLETED,
			comboProjectsLoaded: comboProjectsLoading === LOADING_STATE.COMPLETED,
			groupProjectsLoaded: groupProjectsLoading === LOADING_STATE.COMPLETED
		}

		return {
			...loadingStates,
			siteReady: Object.values(loadingStates).reduce( (allLoaded, itemLoaded) => {
				return allLoaded && itemLoaded
			}, true)
		}
	}

	// wrapHOC = (WrappedComponent) => (props) => (
	// 	<Fragment>
	// 		<SiteHeader />
	// 		<div id="site-body">
	// 			<SiteNav />
	// 			<Measure bounds onResize={this.onResize}>
	//               {({ measureRef }) => (
	// 				<div id="page-wrapper" ref={measureRef}>
	// 					<WrappedComponent {...props}/>
	// 					<div id="page-overlay"></div>
	// 				</div>
	//               )}
	//             </Measure>
	//             <SearchResults />
	//             {/*
	// 				this.props.searchTerm.length && <SearchResults />
	//             */}
	// 			<div id="modal-wrapper"></div>
 //            </div>
	// 	</Fragment>
	// )

	onResize = (e) => {
		this.props.performUpdateScreenProps(e)
	}

  	NoMatch = ({location}) => (
	    <div>
	    	<h1>Oops, Page not found!</h1>
	    </div>
	)

	render() {
		const {searchTerm} = this.props
		if(!this.state.siteReady) {
			return (
				<Fragment>
					<Loader message="Loading Heywood &amp; Condie..." />
				</Fragment>
			)
		}
		return (
			<Router>
				<Fragment>
					<Route component={SiteHeader}/>
					<Route component={SiteNav}/>
					<Measure bounds onResize={this.onResize}>
		            	{({ measureRef }) => (
					<div id="site-body" ref={measureRef}>
						<ConsentBar />
						<div id="page-wrapper">
							<Switch>
								<Route exact path={ROUTE_PATH.HOME.path} component={Home}/>
								<Route path={`${ROUTE_PATH.CATEGORY.path}/:categoryId`} component={Category} />
								<Route path={`${ROUTE_PATH.COMBO.path}/:comboSlug`} component={ComboProject} />
								<Route path={`${ROUTE_PATH.GROUP.path}/:groupSlug`} component={GroupProject} />
								<Route path={`${ROUTE_PATH.PROJECT.path}/:projectId`} component={Project}/>
								<Route path={ROUTE_PATH.NEWS.path} component={News}/>
								<Route path={ROUTE_PATH.ABOUT.path} component={About}/>
								<Route path={ROUTE_PATH.CV.path} component={CV}/>
								<Route path={ROUTE_PATH.CONTACT.path} component={Contact}/>
								<Route path={ROUTE_PATH.PRIVACY.path} component={Privacy}/>
								<Redirect from='/contact.php' push to={ROUTE_PATH.CONTACT.path}/>
								<Redirect from='/about.php' push to={ROUTE_PATH.ABOUT.path}/>
								<Redirect from='/news.php' push to={ROUTE_PATH.NEWS.path}/>
								<Redirect from='/exhibitions.php' push to={'/category/gallery'}/>
								<Redirect from='/installations.php' push to={'/category/horticultural'}/>
								<Redirect from='/' push to={ROUTE_PATH.HOME.path}/>
			          			<Route component={this.NoMatch}/>
							</Switch>
							<div id="page-overlay"></div>
						</div>
						{ searchTerm && <SearchResults />}
						<div id="modal-wrapper"></div>
		            	</div>
					)}
			        	</Measure>
				</Fragment>
			</Router>
		)
	}
}

function mapStateToProps(state){
	return {
		pagesLoading: state.pages.pagesLoading,
		projectsLoading: state.projects.projectsLoading,
		venuesLoading: state.projects.venuesLoading,
		mediaLoading: state.projects.mediaLoading,
		categoriesLoading: state.categories.categoriesLoading,
		comboProjectsLoading: state.comboProjects.loading,
		groupProjectsLoading: state.groupProjects.loading,
		currentPage: state.ui.currentPage,
		searchTerm: state.ui.searchTerm
	}
}

function mapDispatchToProps(dispatch){
	return bindActionCreators({
		performFetchProjects,
		performFetchVenues,
		performFetchPages,
		performFetchCategories,
		performFetchMedia,
		performUpdateScreenProps,
		performFetchComboProjects,
		performFetchGroupProjects
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
