import React, {Fragment} from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import {
    getImgSrcSet,
    getProjectImageAspect,
    getFiletype,
    createDOMElement
} from '../../utils'
import './ImageViewer.css'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { ImageSet } from '../CommonPageElement'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

class ImageViewer extends React.Component {
    constructor(props){
        super(props);
        this.el = createDOMElement('div', {className:'image-viewer-wrapper'})
        this.container = document.getElementById('modal-wrapper')
        this.state = {
            initialSlide: props.images.findIndex(
                img => img.imageid === props.selectedImageId) || 0
        }
    }

    componentDidMount () {
        this.container && this.container.appendChild(this.el);
    }

    componentWillUnmount () {
        this.container && this.container.removeChild(this.el);
    }

    getDataSource = () => {
        const {images, sizes} = this.props
        const dataSource = images.map( img => {
            return {
                srcSet: getImgSrcSet(sizes.map( size => {
                    return {
                        src: img.sizes[size],
                        descriptor: img.sizes[`${size}-width`]+'w'
                    }
                })),
                sizes: sizes.reduceRight( (str, size) => {
                    const maxWidth = img.sizes[`${size}-width`]
                    return `(max-width: ${maxWidth}px) 100vw, ${str}`
                }, `${img.sizes['thumbnail-width']}px`),
                small: {
                    url: img.sizes['large'],
                    width: img.sizes['large-width'],
                    height: img.sizes['large-height']
                },
                large: {
                    url: img.sizes['2048x2048'],
                    width: img.sizes['2048x2048-width'],
                    height: img.sizes['2048x2048-height']
                },
                alt: img.projectTitle,
                image: img
            }
        })
        return dataSource
    }

    renderMagnifier = (imgData) => {
        const { defaultOptions:{zoomOptions}} = this.props
        const {image} = imgData
        const fileInfo = getFiletype(image.src)
        if(fileInfo.filetype === 'image') {
            return (
                <TransformWrapper {...zoomOptions} >
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                    <TransformComponent>
                        <img className="image-viewer__image"
                             src={imgData.large.url}
                             alt={`Image from project: ${image.projectTitle}`}
                        />
                    </TransformComponent>
                    )}
                </TransformWrapper>
            )
        }
        return (
            <video className="image-viewer__video"
                   controls
            >
                <source src={image.src}
                        type={`video/${fileInfo.ext}`} />
                Sorry, your browser doesn't support embedded videos.
            </video>
        )
    }

    renderCarousel = () => {
        const { images, defaultOptions:{carouselOptions} } = this.props
        const { initialSlide } = this.state
        const dataSource = this.getDataSource()
        return (
            <Carousel
                selectedItem={initialSlide}
                {...carouselOptions}
            >
                {dataSource.map((imgData, index) => (
                    <div key={`slick-carousel-image-${index}`}
                         className="image-magnify__wrapper"
                    >
                        { this.renderMagnifier(imgData) }
                    </div>
                ))}
            </Carousel>
        )
    }

    render() {
        const { onCloseClicked } = this.props
        return (
            ReactDOM.createPortal((
                <Fragment>
                    { this.renderCarousel() }
                    <div className="image-viewer__close-btn --dk"
                         onClick={onCloseClicked}
                    >x</div>
                </Fragment>
            ), this.el )
        )
    }
}

ImageViewer.defaultProps = {
    sizes: [
        'thumbnail',
        'medium',
        // 'medium_large',
        'large',
        '1536x1536',
        '2048x2048'
    ],
    defaultOptions: {
        carouselOptions: {
            showArrows: true,
            showStatus: false,
            autoPlay: false,
            infiniteLoop: false,
            swipeable: false,
            emulateTouch: false
        },
        zoomOptions: {
            defaultScale: 1,
            options: {
                minScale: 1,
                maxScale: 5
            },
            scalePadding: {
                size: 0.45
            },
            wheel: {
                step: 150,
                limitsOnWheel: true
            }
            // zoomIn: {
            //     step: 500
            // },
            // zoomOut: {
            //     step: 500
            // }
        }
    }
}

ImageViewer.propTypes = {
    images: PropTypes.array.isRequired,
    onCloseClicked: PropTypes.func.isRequired,
    selectedImageId: PropTypes.number,
    magnifyOptions: PropTypes.object
}

export default ImageViewer