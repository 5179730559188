import React, { Component, Fragment } from 'react'
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import {NavLink, Link} from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {ROUTE_PATH, getRouteBySlug} from '../../constants/global'
import {
	getProjectsByCategory,
	createDOMElement
} from '../../utils'
import {
    performSetCurrentProject
} from '../../actions/action_project'
import './MainMenu.css'
import {Menu} from '../CommonPageElement'

class MainMenu extends React.Component {
	constructor(props){
		super(props);
		this.$el = createDOMElement('div', {
			id:'main-site-menu',
			className:'main-site-menu'
		})
		this.$container = document.getElementById('modal-wrapper')
	}

	componentDidMount () {
		// Append the element into the DOM $container on mount
		this.$container && this.$container.appendChild(this.$el)
	}

	componentWillUnmount () {
		// Remove the element from the DOM this.$container when we unmount
    		this.$container && this.$container.removeChild(this.$el)
	}

	onMenuItemClicked = (e, projectId) => {
		const {performSetCurrentProject, onMenuItemClicked} = this.props
		performSetCurrentProject(projectId)
		onMenuItemClicked()
	}

	render() {
		return (
            ReactDOM.createPortal(
            	<Menu className="main-site-menu__nav"
            		 onMenuItemClicked={this.onMenuItemClicked} />
			, this.$el )
		)
	}
}

MainMenu.defaultProps = {
}

MainMenu.propTypes = {
	currentCategory: PropTypes.string,
	categories: PropTypes.array.isRequired,
	onMenuItemClicked: PropTypes.func,
	$container: PropTypes.object,
	$el: PropTypes.object
}

function mapStateToProps(state) {
  return {
  	homePage: state.pages.pages.find( page => page.slug === 'home'),
  	currentCategory: state.categories.currentCategory,
  	categories: state.categories.categories,
  	projects: state.projects.projects
  }
}

function mapDispatchToProps(dispatch){
	return bindActionCreators({
		performSetCurrentProject
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);
