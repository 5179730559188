import {
	TOGGLE_GALLERY_FORMAT,
	TOGGLE_MAIN_MENU,
	UPDATE_SEARCH_TERM,
	CLEAR_SEARCH_TERM,
	UPDATE_SCREEN_PROPS,
	SET_CURRENT_PAGE,
	RESTORE_PREV_SEARCH_TERM
} from '../constants/actionTypes'

export function performToggleGalleryFormat(format) {
	return {
		type: TOGGLE_GALLERY_FORMAT,
		format
	}
}

export function performToggleMainMenu(show) {
	return {
		type: TOGGLE_MAIN_MENU,
		show
	}
}

export function performUpdateSearchTerm(searchTerm) {
	return {
		type: UPDATE_SEARCH_TERM,
		searchTerm
	}
}

export function performClearSearchTerm(updatePrevSearchTerm = false) {
	return {
		type: CLEAR_SEARCH_TERM,
		updatePrevSearchTerm
	}
}

export function performRestorePrevSearchTerm() {
	return {
		type: RESTORE_PREV_SEARCH_TERM
	}
}

export function performUpdateScreenProps(props) {
	return {
		type: UPDATE_SCREEN_PROPS,
		props
	}
}

export function performSetCurrentPage(pageId, categoryId = null) {
	return {
		type: SET_CURRENT_PAGE,
		pageId,
		categoryId
	}
}