// import './ProjectsListItem.css'
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "react-router-dom";
import { FlipPanel, FlipPanelToggleButton } from "../FlipPanel";
import {
  getProjectImageAspect,
  getVenueAddress
} from '../../utils'
import {getRandomHexColour, getMediaById} from '../../utils'
import ScrollArea from 'react-scrollbar'
import './ProjectsListItem.css'
// import PlaceholderImage from "../../assets/images/placeholder-image.png";

class ProjectsListItem extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      flipside: 'front'
    }
  }

  handleFlipPanelToggle = (e) => {
    const {flipside} = this.state
    this.setState({
      flipside: flipside === 'front' ? 'back' : 'front'
    })
  }

  renderHeader = () => {
    const { id, projectTitle } = this.props.project
    const {venue, project, project:{projectLocation:{projectEventTitle}}} = this.props
    const address = venue ? venue.venueAddress : project.projectLocation.projectManualAddress
    const addressFields = getVenueAddress(address)
    return (
      <div className="project-list-item__header">
        <h3 className="project-list-item__title">{projectTitle}</h3>
      </div>
    )
  }

  renderProjectLocation = () => {
    const {venue, project, project:{projectLocation:{projectEventTitle}}} = this.props
    const address = venue ? venue.venueAddress : project.projectLocation.projectManualAddress
    const addressFields = getVenueAddress(address)
    return (
      <div className="cell project-info__address">
        <h3>Where.</h3>
        <div className="project-location">
            {
                projectEventTitle !== "" && <h4>projectEventTitle</h4>
            }
            <div className="project-location__address">
            {addressFields.map( (field, index) => {
                return <div key={`address-field${index}`}>{field}</div>
            })}
            </div>
        </div>
      </div>
    )
  }

  renderPanelFront = () => {
    const {project, project:{id, projectImages} } = this.props
    const titleImage = projectImages[0]
    const aspect = getProjectImageAspect(titleImage)
    const aspectRatioClass = aspect.ratio < aspect.defaultRatio ? '--match-height' : ''
    const thumbnailUrl = titleImage && titleImage.url
    return (
        <Link
          className="thumbnail"
          to={{ pathname: `/project/${id}` }}
        >
          {thumbnailUrl &&
            <img className={`content ${aspectRatioClass}`}
                 src={thumbnailUrl}
                 alt={titleImage.name}
                 title={titleImage.name}
            />
          }
        </Link>
    )
  }

  renderPanelBack = () => {
    const {projectDescription} = this.props.project
    return (
      <ScrollArea
        speed={0.8}
        className="project-info"
        contentClassName="content"
        horizontal={false}
       >
        <div className="grid-y ">
          <div className="project-info__description"
               dangerouslySetInnerHTML={{__html: projectDescription}}
          ></div>
          {
          //this.renderProjectLocation()
          }
        </div>
      </ScrollArea>
    )
  }

  renderFlipPanel = () => {
    const {flipside} = this.state
    return (
      <FlipPanel currentSide={flipside} >
        {this.renderPanelFront()}
        {this.renderPanelBack()}
      </FlipPanel>
    )
  }

  renderFooter = () => {
    /*const {project:{projectDescription}} = this.props

    if(projectDescription.length) {
      return (
        <FlipPanelToggleButton
          onClickHandler={this.handleFlipPanelToggle}
        />
      )
    }*/

    return null
  }

	render() {
    const { media, project:{projectImages} } = this.props
    const titleImageId = projectImages[0].id
    const mediaObj = getMediaById(media, titleImageId)
    // let bgColour
    // if(mediaObj) {
    //   bgColour = mediaObj.dominantColour
    // }
    // else {
    //   bgColour = getRandomHexColour({
    //     greyscale: true,
    //     min: 1,
    //     max: 16
    //   })
    // }
    // bgColour = '#ffffff'
		return (
      <div className="cell project-list-item small-6 medium-4 large-3">
        <div className="project-list-item__bg"></div>
        {this.renderHeader()}
        <div className="project-list-item__content">
          {this.renderFlipPanel()}
        </div>
        {this.renderFooter()}
      </div>
		)
	}
}

ProjectsListItem.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.number.isRequired,
    projectImages: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.bool
    ]),
    projectDescription: PropTypes.string
  }).isRequired,
  media: PropTypes.array.isRequired
}

export default ProjectsListItem