import React, {Component} from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getProjectDate } from '../../../utils'

class ProjectDate extends Component {
	render() {
		const {
			className,
			defaultClassName,
			project:{projectDates:{projectStartDate}},
			project:{projectDates:{projectEndDate}}
		} = this.props
		const projectDate = getProjectDate(projectStartDate, projectEndDate)
		const parsedClass = className && className.length ? ` ${className}` : ''

		if(!projectDate) {
			return null
		}
		return (
			<div className={`${defaultClassName}${parsedClass}`}>
				{	projectDate &&
					<div className={`${defaultClassName}__info`}>{projectDate}</div>
				}
			</div>
		)
	}
}
	
ProjectDate.defaultProps = {
	defaultClassName: 'common-project-date'
}

ProjectDate.propTypes = {
	project: PropTypes.object.isRequired
}

function mapStateToProps(state) {
	return {
		venues: state.projects.venues
	}
}

export default connect(mapStateToProps, null)(ProjectDate);
