

export const ROUTE_PATH = {
    HOME: {
        path: "/",
        slug: "home"
    },
    CATEGORY: {
        path: "/category",
        slug: "category"
    },
    COMBO: {
        path: "/combo",
        slug: "combo"
    },
    GROUP: {
        path: "/group",
        slug: "group"
    },
    CONTACT: {
        path: "/contact",
        path_old: "/contact.php",
        slug: "contact"
    },
    PROJECT: {
        path: "/project",
        slug: "project"
    },
    NEWS: {
        path: "/news",
        slug: "news"
    },
    ABOUT: {
        path: "/about",
        slug: "about"
    },
    CV: {
        path: "/cv",
        slug: "cv"
    },
    PRIVACY: {
        path: "/privacy",
        slug: "privacy"
    }
}

export function getRouteBySlug(slug) {
    return Object.values(ROUTE_PATH).find( route => {
        return route.path.includes(slug)
    }) || ROUTE_PATH.HOME
}

export const LOADING_STATE = {
    IDLE: 'idle',
    STARTED: 'started',
    COMPLETED: 'completed',
    ERROR: 'error'
}

export const PAGES = ["home", "project", "group", "combo", "category", "cv", "news", "contact"]

export const DEFAULT_PROJECT_IMAGE = {
    ratio: 1.5,
    backgroundColour: '#000000'
}

export const BREAKPOINT = {
    small: {
        min: 0,
        max: 639
    },
    medium: {
        min: 640,
        max: 1023
    },
    large: {
        min: 1024,
        max: 1199
    },
    xlarge: {
        min: 1200,
        max: 999999
    }
}

export const POST_TYPE = {
    PROJECT: "project",
    COMBO_PROJECT: "comboprojects",
    GROUP_PROJECT: "groupprojects"
}


// *** When new icons are added, remember to update
// *** the $iconList in _mixins.scss
export const ICONS = {
    compass2: {filename: "compass2.svg"},
    map: {filename: "map.svg"},
    map2: {filename: "map2.svg"},
    info: {filename: "info.svg"},
    'cancel-circle': {filename: "cancel-circle.svg"},
    cross: {filename: "cross.svg"},
    checkmark: {filename: "checkmark.svg"},
    play2: {filename: "play2.svg"},
    pause: {filename: "pause.svg"},
    stop: {filename: "stop.svg"},
    previous: {filename: "previous.svg"},
    next: {filename: "next.svg"},
    backward: {filename: "backward.svg"},
    forward2: {filename: "forward2.svg"},
    play3: {filename: "play3.svg"},
    pause2: {filename: "pause2.svg"},
    stop2: {filename: "stop2.svg"},
    backward2: {filename: "backward2.svg"},
    forward3: {filename: "forward3.svg"},
    first: {filename: "first.svg"},
    last: {filename: "last.svg"},
    previous2: {filename: "previous2.svg"},
    next2: {filename: "next2.svg"},
    'circle-down': {filename: "circle-down.svg"},
    'circle-left': {filename: "circle-left.svg"},
    'circle-right': {filename: "circle-right.svg"},
    'circle-up': {filename: "circle-up.svg"},
    mail4: {filename: "mail4.svg"},
    facebook2: {filename: "facebook2.svg"},
    instagram: {filename: "instagram.svg"},
    whatsapp: {filename: "whatsapp.svg"},
    telegram: {filename: "telegram.svg"},
    twitter: {filename: "twitter.svg"},
    youtube2: {filename: "youtube2.svg"},
    collage: {filename: "collage.svg"},
    grid: {filename: "grid.svg"},
    horticultural: {filename: "horticultural.svg"},
    sculpture: {filename: "sculpture.svg"},
    gallery: {filename: "gallery.svg"},
    'horticultural-solid': {filename: "horticultural-solid.svg"},
    'sculpture-solid': {filename: "sculpture-solid.svg"},
    'gallery-solid': {filename: "gallery-solid.svg"},
    'horticultural-solid-lt': {filename: "horticultural-solid-lt.svg"},
    'sculpture-solid-lt': {filename: "sculpture-solid-lt.svg"},
    'gallery-solid-lt': {filename: "gallery-solid-lt.svg"},
    default: {
        filename: "default.svg",
        classname: '--icon-default'
    },
    get: function (iconId) {
        const icon = ICONS[iconId]
        return icon
        ? { ...icon,
            classname: `--icon-${iconId}`
        }
        : ICONS.default
    }
}