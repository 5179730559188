import {
	FETCH_COMBO_PROJECTS_STARTED,
	FETCH_COMBO_PROJECTS_COMPLETED
} from '../constants/actionTypes'
import { shapeWordpressProject } from '../utils'
import { LOADING_STATE } from '../constants/global'
import moment from 'moment'

const initialState = {
	projects: [],
	loading: LOADING_STATE.IDLE
}

function fetchComboProjectsStarted(state, action){
	return {
		...state,
		loading: LOADING_STATE.STARTED
	}
}

function fetchComboProjectsCompleted(state, action){
	return {
		...state,
		loading: LOADING_STATE.COMPLETED,
		projects: action.payload.data
	}
}

export default function ComboProjectsReducer(state = initialState, action) {
	switch (action.type) {

		case FETCH_COMBO_PROJECTS_STARTED:
			return fetchComboProjectsStarted(state, action)

		case FETCH_COMBO_PROJECTS_COMPLETED:
			return fetchComboProjectsCompleted(state, action)
			
		default:
			return state
	}
}
