import {
	FETCH_CATEGORIES
} from '../constants/actionTypes'

import {
	shapeWordpressCategory
} from '../utils'
import { LOADING_STATE } from '../constants/global'

const initialState = {
	categories: [],
	currentCategory: null,
	categoriesLoading: LOADING_STATE.IDLE
}

function fetchCategories(state, action){
	const {data} = action.payload
	return {
		...state,
		categoriesLoading: LOADING_STATE.COMPLETED,
		categories: data && data.map(category => {
			return shapeWordpressCategory(category)
		}) || state.categories
	}
}

export default function CategoriesReducer(state = initialState, action) {
	switch (action.type) {
		case FETCH_CATEGORIES:
			return fetchCategories(state, action)
			
		default:
			return state
	}
}
