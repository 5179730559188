import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { getImgSrcSet } from '../../../utils'

class ImageSet extends Component {
     constructor(props){
          super(props);
          this.state = {}
     }

     getSrcSet = () => {
          const {wpImageSizes, sizes} = this.props
          const srcSet = getImgSrcSet(sizes.map( size => {
               return {
                   src: wpImageSizes[size],
                   descriptor: wpImageSizes[`${size}-width`]+'w'
               }
          }))
          return srcSet
     }

     getSizes = () => {
          const {
               wpImageSizes,
               sizes
          } = this.props
          return sizes.reduceRight( (str, size) => {
               const maxWidth = wpImageSizes[`${size}-width`]
               return `(max-width: ${maxWidth}px) ${maxWidth}px, ${str}`
          }, `${wpImageSizes['thumbnail-width']}px`)
     }

     render() {
          const {
               wpImageSizes,
               sizes,
               ...passthroughProps
          } = this.props
          return (
               <img src={wpImageSizes[sizes[0]]}
                    srcSet={this.getSrcSet()}
                    sizes={`(max-width: 2560px) 100vw, ${wpImageSizes['thumbnail-width']}px`}
                    // sizes={this.getSizes()}
                    {...passthroughProps}
               />
          )
     }
}

ImageSet.defaultProps = {
     sizes: [
          'thumbnail',
          'medium',
          // 'medium_large',
          'large',
          '1536x1536',
          '2048x2048'
     ]
}

ImageSet.propTypes = {
    wpImageSizes: PropTypes.object.isRequired
}

export default ImageSet;