import {
	FETCH_GROUP_PROJECTS_STARTED,
	FETCH_GROUP_PROJECTS_COMPLETED
} from '../constants/actionTypes'

import {fetchGroupProjects} from '../services/wordpressAPI'

export function fetchGroupProjectsStarted() {
	return {
		type: FETCH_GROUP_PROJECTS_STARTED
	}
}

export function fetchGroupProjectsCompleted(payload) {
	return {
		type: FETCH_GROUP_PROJECTS_COMPLETED,
		payload
	}
}

export function performFetchGroupProjects() {
	return dispatch => {
		dispatch(fetchGroupProjectsStarted())
		return fetchGroupProjects()
			.then(response => dispatch(fetchGroupProjectsCompleted(response)))
	}
}