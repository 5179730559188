import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import './ComboProjectItem.css'
import {
	ProjectDescription,
	ProjectLocation,
	ProjectMaterials,
	ProjectDate,
	SectionTitle
} from '../../CommonPageElement'
import {
	getCollageOfProjectImages
} from '../../../utils'
import {ImageCollage, IMAGE_COLLAGE} from '../../ImageCollage'

class ComboProjectItem extends Component {
	constructor(props){
		super(props);
		this.state = {
			selectedImageId: null
		}
	}

	renderCollage = () => {
		const {
			project,
			project:{projectImages},
			onCollageImageClicked
		} = this.props

		if(!projectImages.length){
			return null
		}
		const collageImages = getCollageOfProjectImages(project)
		// const isSingleImage = collageImages.length === 1
		// let singleImageClass = isSingleImage ? ' --single-image' : ''
		// if(isSingleImage) {
		// 	const img = collageImages[0]
		// 	singleImageClass = img.width >= img.height
		// 		? `${singleImageClass} --landscape`
		// 		: `${singleImageClass} --portrait`
		// }
		// const classes = `combo-project-item__images${singleImageClass}`
		return (
			<ImageCollage
				className="combo-project-item__images"
				images={collageImages}
				onClick={onCollageImageClicked}
				showGroups={false}
				type={IMAGE_COLLAGE.TYPE.COMBO}
				targetRatios={{
					...IMAGE_COLLAGE.TARGET_RATIOS,
					small: 2
				}}
			/>
		)
	}

	renderProject = () => {
		const {project} = this.props
		return (
			<Fragment>
				<SectionTitle className="combo-project-item__title">
					{project.projectTitle}
				</SectionTitle>
				<div className="combo-project-item__info">
					<ProjectLocation className="combo-project-item__info__location"
									 project={project} />
					<ProjectDate className="combo-project-item__info__date"
								 project={project} />
					<ProjectMaterials className="combo-project-item__info__materials"
									  project={project} />
					<ProjectDescription className="combo-project-item__info__description"
										project={project} />
				</div>
				{this.renderCollage()}
			</Fragment>
			)
	}

	render() {
		const {
			className,
			project:{projectImages}
		} = this.props
		const parsedClass = className ? ` ${className}` : ''
		let imageCountClass = ` --image-count-${projectImages.length}`
		if(projectImages.length === 1) {
			const img = projectImages[0]
			imageCountClass = img.width >= img.height
				? `${imageCountClass} --landscape`
				: `${imageCountClass} --portrait`
		}
		const classes = `combo-project-item${parsedClass}${imageCountClass}`
		return (
			<section className={classes}>
			{this.renderProject()}
			</section>
			)
	}
}

ComboProjectItem.defaultProps = {}

ComboProjectItem.propTypes = {
	project: PropTypes.object.isRequired,
	onCollageImageClicked: PropTypes.func.isRequired
}

function mapStateToProps(state) {
	return {}
}

export default connect(mapStateToProps)(ComboProjectItem);