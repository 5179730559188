import React, {Component} from 'react'
import PropTypes from 'prop-types'

class SectionTitle extends Component {
    render() {
        const {children, className} = this.props
        const parsedClass = className && className.length ? ` ${className}` : ''
        const classes = `common-section-title${parsedClass}`
        return <h2 className={classes}>{children}</h2>
    }
}

SectionTitle.propTypes = {
    subTitle: PropTypes.string
}

export default SectionTitle;