import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import './CV.css'
import { performSetCurrentPage } from '../../actions/action_ui'
import { getProjectById, getVenueAddressString } from '../../utils'
import {
	ROUTE_PATH,
	POST_TYPE
} from '../../constants/global'
import {
	PageTitle,
	ScrollArea
} from '../CommonPageElement'

class CV extends Component {
	constructor(props){
		super(props)
		props.performSetCurrentPage('cv')
	}

	getRelatedImage = (content) => {
		const {
			relatedProjectList,
			image
		} = content
		const { comboProjects, groupProjects } = this.props
		let projectImage

		if(image) {
			return (
				<div className="cv-page__content__entry__image">
					<img src={`${image}`} alt="Project Image" />
				</div>
			)
		}

		if(relatedProjectList) {
			const type = content.relatedProjectList.post_type

			if(type === POST_TYPE.COMBO_PROJECT) {
				projectImage = comboProjects.reduce( (image, p) => {
					if(!image && p.id === content.id) {
						return p.acf.titleImage.url
					}
					return image
				}, null)
			}
			projectImage = relatedProjectList.reduce( (image, p) => {
				// const project = getProjectById(this.props.projects, p.ID)
				const type = p.post_type
				let project
				if(type === POST_TYPE.COMBO_PROJECT) {
					project = comboProjects.find( cp => cp.id === p.ID)
					if(!image && project) {
						return project.acf.titleImage.url
					}
				}
				if(type === POST_TYPE.GROUP_PROJECT) {
					project = groupProjects.find( gp => gp.id === p.ID)
					if(!image && project) {
						return project.acf.titleImage.url
					}
				}
				if(type === POST_TYPE.PROJECT) {
					project = getProjectById(this.props.projects, p.ID)
					if(!image && project && project.projectImages.length) {
						return project.projectImages[0].url
					}
				}
				return image
			}, null)
		}

		if(projectImage) {
			return (
				<div className="cv-page__content__entry__image">
					<img src={`${projectImage}`} alt="Project Image" />
				</div>
			)
		}
		return null
	}

	getRelatedProjects = (content) => {
		const { relatedProjectList } = content
		if(!relatedProjectList.length) {
			return null
		}
		return (
			<div className="cv-page__content__entry__related-projects">
				<div className="cv-page__content__entry__project-subtitle">Related projects:</div>
			{	relatedProjectList.map( (project, index) => {
					const type = project.post_type
					let routePath, projectId
					switch(type) {
						case POST_TYPE.COMBO_PROJECT:
							routePath = ROUTE_PATH.COMBO.path
							projectId = project.post_name
							break;
						case POST_TYPE.GROUP_PROJECT:
							routePath = ROUTE_PATH.GROUP.path
							projectId = project.post_name
							break;
						default:
							routePath = ROUTE_PATH.PROJECT.path
							projectId = project.ID
					}

					return (
						<NavLink key={`cv-page-entry-related-project-${index}-${projectId}`}
							    className="cv-page__content__entry__related-projects__link"
							    to={`${routePath}/${projectId}`}
						>{project.post_title}</NavLink>
					)
				})
			}
			</div>
		)
	}

	renderEntries = (entry, year) => {
		return (
			<Fragment>
			{
				entry.map( (e, index) => {
					const {
						manualEntryTitle,
						manualEntryLocation,
						relatedProjectList,
						image
					} = e
					return (
						<div className="cv-page__content__entry" key={`cv-page-entry-${year}-${index}`}>
							<div className="cv-page__content__entry__header">
								<div className="cv-page__content__entry__project-title">{`${manualEntryTitle}`}</div>
								<div className="cv-page__content__entry__location">{`${manualEntryLocation}`}</div>
							</div>
							<div className="cv-page__content__entry__footer">
								{ this.getRelatedProjects(e) }
							</div>
							{ this.getRelatedImage(e) }
						</div>
					)
				})
			}
			</Fragment>
		)
	}

	renderContent = () => {
		const {cv} = this.props
		const {content, title, subtitle} = cv
		if(!content && content.length) {return null}
		return (
			content.map( (item) => {
				const {year, entry} = item
				return (
					<section key={`cv-page-section-${year}`}>
						<div className={'cv-page__content__year'}>
							<span>{year}</span>
						</div>
						{ this.renderEntries(entry, year) }
					</section>
				)
			})
		)
	}

	render() {
		return (
			<ScrollArea className="cv-page"
					  scrollKey={ROUTE_PATH.CV.slug}>
				<div className="content-wrapper">
					<PageTitle className="cv-page__title">
	                        CV
	                    </PageTitle>
					<div className="cv-page__content">
				        {this.renderContent()}
					</div>
				</div>
			</ScrollArea>
		)
	}
}

CV.propTypes = {

}

function mapStateToProps(state) {
  return {
  	projects: state.projects.allProjects,
  	comboProjects: state.comboProjects.projects,
  	groupProjects: state.groupProjects.projects,
  	cv: state.pages.pages.find( page => {
  		return page.slug === 'cv'
  	})
  }
}

export default connect(mapStateToProps, {
	performSetCurrentPage
})(CV);