import {
	FETCH_PAGES_STARTED,
	FETCH_PAGES_COMPLETED,
	FETCH_PAGES_ERROR,
	FETCH_PAGE_BY_ID,
	GET_PAGES,
	SET_CURRENT_PAGE
} from '../constants/actionTypes'

import {
	asyncFetchDataCompleted,
	asyncFetchDataError,
	asyncFetchDataStarted
} from "./actionsAsyncDataFetching";

import {
	fetchPages as fetchPagesFromAPI,
	fetchPageById
} from '../services/wordpressAPI'

function fetchPages(dispatch) {
    dispatch(asyncFetchDataStarted(FETCH_PAGES_STARTED));

    return fetchPagesFromAPI().then((data) => {
        dispatch(asyncFetchDataCompleted(FETCH_PAGES_COMPLETED, data));
    }).catch((err) => {
        dispatch(asyncFetchDataError(FETCH_PAGES_ERROR, err));
    });
}

export function performFetchPages() {
	// const request = fetchPages();
	// return {
	// 	type: FETCH_PAGES,
	// 	payload: request
	// };
	return function (dispatch) {
        return fetchPages(dispatch);
    };
}

export function performFetchPageById(id) {
	const request = fetchPageById(id);
	return {
		type: FETCH_PAGE_BY_ID,
		payload: request,
		id
	};
}

export function performGetPages() {
	return {
		type: GET_PAGES,
		payload: []
	}
}

export function performSetCurrentPage(pageId) {
	return {
		type: SET_CURRENT_PAGE,
		pageId
	}
}