import React, {Fragment} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import Measure from 'react-measure'
import {Link} from 'react-router-dom'
import VideoThumbnail from 'react-video-thumbnail'
import Img from 'react-image'
import {
    getCollageImageObject,
    getTruncatedText,
    getGroupById
} from '../../utils'
import './ImageCollage.css'
import Loader from '../Loader/Loader'
import {ImageSet} from '../CommonPageElement'

const TYPE = {
    AUTO: "auto",
    PROJECT: "project",
    GROUP: "group",
    COMBO: "combo"
}

const TARGET_RATIOS = {
    'small': 2,
    'medium': 2,
    'large': 3,
    'xlarge': 4
}

export const IMAGE_COLLAGE = {
    TYPE,
    TARGET_RATIOS
}

class ImageCollage extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            containerWidth: 0
        }
    }

    componentDidUpdate() {
        var videos = document.getElementsByTagName("video")
        for(let i = 0; i<videos.length; ++i){
            videos[i].currentTime = 5
        }
    }

    getTargetRatio = () => {
        const {targetRatios, screenProps} = this.props
        return targetRatios[screenProps.breakpoint.current]
    }

    formatCollageImages = (images) => {
        const {colGutter, isolateLastRow} = this.props
        let cImages = []
        let currRow = []
        let currRowIndex = 0
        let currentRowRatio = 0
        const targetRatio = this.getTargetRatio()

        const processRow = (isLastRow = false) => {
            // Combine ratio of each image in the row
            // to get the rows ratio
            let totalRatio = currRow.reduce( (acc, img) => {
                return acc + img.ratio
            },0)
            if(isLastRow){
                totalRatio = Math.max(totalRatio, targetRatio) 
            }
            const {containerWidth} = this.state
            const totalGutter = (currRow.length-1) * colGutter
            const widthPcent = (containerWidth - totalGutter) / containerWidth

            cImages = [...cImages].concat(
                currRow.map( img => {
                    const scale = (img.ratio / totalRatio) * widthPcent
                    return {
                        ...img,
                        widthPcent: widthPcent,
                        scale: scale,
                        cWidth: img.width * scale,
                        cHeight: img.height * scale,
                        rowIndex: currRowIndex,
                        isLastRow: /*currRowIndex && */isolateLastRow && isLastRow
                    }
                })
            )
            ++currRowIndex
        }

        const diff = ((val1, val2) => {
            if(val1 === val2) {
                return 0
            }
            return Math.max(val1, val2) - Math.min(val1, val2)
        })

        images.forEach( (image, index) => {
            const nextRowRatio = currentRowRatio + image.ratio
            if(diff(targetRatio, currentRowRatio) < diff(targetRatio, nextRowRatio)){
                processRow()
                currRow = [image]
                currentRowRatio = image.ratio
            }
            else {
                currRow.push(image)
                currentRowRatio += image.ratio
            }
            if(index >= images.length-1) {
                processRow(true)
            }
        })
        return cImages
    }

    onResize = (e) => {
        this.setState({
            containerWidth: e.bounds.width
        })
    }

    getGroupProjectById = (id, prop = 'id') => {
        const {groupProjects} = this.props
        return groupProjects.find( project => project[prop] === id)
    }

    getComboProjectById = (id, prop = 'id') => {
        const {comboProjects} = this.props
        return comboProjects.find( project => project[prop] === id)
    }

    getCollageImages = (imagesByType) => {
        const images = [...imagesByType.default]
        Object.keys(imagesByType.grouped).forEach( groupSlug => {
            const group = this.getGroupProjectById(groupSlug, 'slug')
            const img = getCollageImageObject(group.acf.titleImage, group.acf.date)
            img.projectTitle = group.title.rendered
            img.groupid = groupSlug
            images.unshift(img)
        })
        Object.keys(imagesByType.combined).forEach( comboSlug => {
            const combo = this.getComboProjectById(comboSlug, 'slug')
            const img = getCollageImageObject(combo.acf.titleImage, combo.acf.date)
            img.projectTitle = combo.title.rendered
            img.comboid = comboSlug
            images.unshift(img)
        })
        return images.sort( (img1, img2) => {
            return img2.date.localeCompare(img1.date)
        })
    }

    getCollageImagesByType = (collageImages) => {
        const images = {
            grouped: {},
            combined: {},
            default: []
        }

        collageImages.forEach( img => {
            const groups = img.projectTaxonomy.projectGroup && img.projectTaxonomy.projectGroup.projectGroupName
            const combos = img.projectTaxonomy.projectCombo && img.projectTaxonomy.projectCombo.projectComboName
            if(groups) {
                groups.forEach( groupId => {
                    const group = this.getGroupProjectById(groupId)
                    const slug = group.slug
                    if(!images.grouped[slug]) {
                        images.grouped[slug] = []
                    }
                    images.grouped[slug].push(img)
                })
            }
            else if(combos) {
                combos.forEach( comboId => {
                    const combo = this.getComboProjectById(comboId)
                    const slug = combo.slug
                    if(!images.combined[slug]) {
                        images.combined[slug] = []
                    }
                    images.combined[slug].push(img)
                })
            }
            else {
                images.default.push(img)
            }
        })

        Object.keys(images.grouped).forEach( groupId => {
            const group = images.grouped[groupId]
            group.sort( (img1, img2) => {
                const img1Order = parseInt(img1.projectTaxonomy.projectGroup.projectGroupOrder, 10)
                const img2Order = parseInt(img2.projectTaxonomy.projectGroup.projectGroupOrder, 10)
                return img1Order - img2Order
            })
        })

        Object.keys(images.combined).forEach( comboId => {
            const combo = images.combined[comboId]
            combo.sort( (img1, img2) => {
                const img1Order = parseInt(img1.projectTaxonomy.projectCombo.projectComboOrder, 10)
                const img2Order = parseInt(img2.projectTaxonomy.projectCombo.projectComboOrder, 10)
                return img1Order - img2Order
            })
        })
        return images
    }

    renderImage = (image) => {
        const {onClick, colGutter, rowGutter, showTitles} = this.props
        const {containerWidth} = this.state
        const imgStyle = {
            height: 'auto',
            width: `${image.scale * 100}%`,
            marginBottom: showTitles ? `${rowGutter+30}px` : `${rowGutter}px`,
            marginLeft: image.isLastRow ? `${colGutter}px` : '0px'
            // transform: `scale(${image.widthPcent})`
        }
        const currentImgWidth = containerWidth * image.scale
        const displayedTitle = getTruncatedText(image.projectTitle, 8, currentImgWidth)
        const videoClass = image.type === 'video' ? ' --video' : ''
        const orientationClass = ` --${image.orientation}`
        const classes = `image-collage__item${videoClass}${orientationClass}`

        return (
            <div key={`collage-img-${image.projectid}-${image.imageid}`}
                className={classes}
                style={imgStyle}
                // to={`/project/${image.projectid}`}
            >
                <ImageSet className="image-collage__item__image"
                         wpImageSizes={image.sizes}
                         onClick={ e => onClick(e, image) }
                />
                {showTitles &&
                    <span className="image-collage__item__title"
                          title={image.projectTitle} >{displayedTitle}</span>
                }
            </div>
        )
    }

    renderCollageImages = () => {
        const {images, showGroups, type} = this.props
        let collageImages = images
        if(type === TYPE.AUTO) {
            const collageImagesByType = this.getCollageImagesByType(images)
            collageImages = this.getCollageImages(collageImagesByType)
        }
        const formattedCollageImages = this.formatCollageImages(collageImages)
        const formattedLastRowImages = formattedCollageImages.filter( image => image.isLastRow )
        return (
            <section className="image-collage__group --ungrouped">
                { formattedCollageImages
                    .filter( image => !image.isLastRow )
                    .map( image => this.renderImage(image))
                }
                { formattedLastRowImages.length
                    ? (
                        <div className="image-collage__last-row">
                            { formattedLastRowImages.map( image => this.renderImage(image)) }
                        </div>
                    )
                    : null 
                }
            </section>
        )
    }

    render() {
        const {className} = this.props
        const parsedClass = className && className.length ? ` ${className}` : ''
        const classes  =`image-collage${parsedClass}`
        return (
            <Measure bounds onResize={this.onResize}>
              {({ measureRef }) => (
                <div className={classes} ref={measureRef}>
                    {this.renderCollageImages()}
                </div>
              )}
            </Measure>
        )
    }
}

ImageCollage.defaultProps = {
    forceJustify: false,
    colGutter: 20,
    rowGutter: 50,
    showTitles: false,
    showGroups: true,
    targetRatios: TARGET_RATIOS,
    type: TYPE.AUTO,
    isolateLastRow: true
}

ImageCollage.propTypes = {
    images: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired,
    showTitles: PropTypes.bool,
    showGroups: PropTypes.bool,
    isolateLastRow: PropTypes.bool,
    targetRatios: PropTypes.object,
    forceJustify: PropTypes.bool,
    type: PropTypes.oneOf( Object.values(TYPE) )
}

function mapStateToProps(state) {
  return {
    groupProjects: state.groupProjects.projects,
    comboProjects: state.comboProjects.projects,
    groups: state.projects.groups,
    screenProps: state.ui.screenProps
  }
}

export default connect(mapStateToProps)(ImageCollage);