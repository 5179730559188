

// PROJECT
export const FETCH_PROJECTS_STARTED = 'FETCH_PROJECTS_STARTED'
export const FETCH_PROJECTS_COMPLETED = 'FETCH_PROJECTS_COMPLETED'
export const FETCH_COMBO_PROJECTS_STARTED = 'FETCH_COMBO_PROJECTS_STARTED'
export const FETCH_COMBO_PROJECTS_COMPLETED = 'FETCH_COMBO_PROJECTS_COMPLETED'
export const FETCH_GROUP_PROJECTS_STARTED = 'FETCH_GROUP_PROJECTS_STARTED'
export const FETCH_GROUP_PROJECTS_COMPLETED = 'FETCH_GROUP_PROJECTS_COMPLETED'
export const FETCH_PROJECT_BY_ID = 'FETCH_PROJECT_BY_ID'
export const GET_PROJECTS_BY_GROUP = 'GET_PROJECTS_BY_GROUP'
export const GET_PROJECTS_BY_CATEGORY = 'GET_PROJECTS_BY_CATEGORY'
export const SEARCH_PROJECTS_BY_TITLE = 'SEARCH_PROJECTS_BY_TITLE'
export const SET_CURRENT_PROJECT = 'SET_CURRENT_PROJECT'

// VENUE
export const FETCH_VENUES = 'FETCH_VENUES'
export const FETCH_VENUE_BY_ID = 'FETCH_VENUE_BY_ID'
export const GET_VENUES = 'GET_VENUES'

// PAGE
export const FETCH_PAGES_STARTED = 'FETCH_PAGES_STARTED'
export const FETCH_PAGES_COMPLETED = 'FETCH_PAGES_COMPLETED'
export const FETCH_PAGES_ERROR = 'FETCH_PAGES_ERROR'
export const FETCH_PAGE_BY_ID = 'FETCH_PAGE_BY_ID'
export const GET_PAGES = 'GET_PAGES'

// CATEGORY
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES'

// GROUP
export const FETCH_GROUPS = 'FETCH_GROUPS'

// MEDIA
export const FETCH_MEDIA = 'FETCH_MEDIA'

// UI
export const TOGGLE_GALLERY_FORMAT = 'TOGGLE_GALLERY_FORMAT'
export const TOGGLE_MAIN_MENU = 'TOGGLE_MAIN_MENU'
export const UPDATE_SEARCH_TERM = 'UPDATE_SEARCH_TERM'
export const CLEAR_SEARCH_TERM = 'CLEAR_SEARCH_TERM'
export const RESTORE_PREV_SEARCH_TERM = 'RESTORE_PREV_SEARCH_TERM'
export const UPDATE_SCREEN_PROPS = 'UPDATE_SCREEN_PROPS'
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'

export const FETCH_NEWS = 'FETCH_NEWS'